export const getRedirectUrlNoParams = ({ host, path = '', redmineToken, isWithAuth = false }) => {
  switch (host) {
    case 'https://rbdatagroup.x-lance.ru': {
      return `https://redmine.rbdata.group${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://dochubdemo.rbdata.group': {
      return `https://dochubrmdemo.rbdata.group${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://xl.davrbank.uz': {
      return `https://xladmin.davrbank.uz${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://opt2.cybernix.ru': {
      return `https://vg-test.cybernix.ru:3443${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://opt2.cybernix.ru:10062': {
      return `https://vg-test.cybernix.ru:10053${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://opt2.rbtechnologies.ru:10061': {
      return `https://vg-test.rbtechnologies.ru:3443${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }`;
    }
    case 'https://opt2.rbtechnologies.ru:10060': {
      return `https://opt2.rbtechnologies.ru:9909${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://opt2.rbtechnologies.ru:10062': {
      return `https://opt2.rbtechnologies.ru:10053${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }`;
    }
    case 'https://demo-new.x-lance.ru': {
      return `https://opt2.rbtechnologies.ru:10052${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }`;
    }
    case 'https://old-2935-redmine.rbtechnologies.ru': {
      return `https://redmine.rbtechnologies.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://rbtechnologies.x-lance.ru': {
      return `https://redmine.rbtechnologies.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://idft.x-lance.ru': {
      return `https://redmine.idft.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://cybernix.x-lance.ru': {
      return `https://redmine.cybernix.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://xlancerb.fst.sh': {
      return `https://redmine.fst.sh${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'http://192.168.96.30': {
      return `http://192.168.96.30:8080${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}`;
    }
    case 'https://opt2.rbtechnologies.ru:10063': {
      return `https://opt2.rbtechnologies.ru:10054${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }`;
    }
    default: {
      return host;
    }
  }
};
