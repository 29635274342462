import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import issueFormFieldStyles from './IssueFormField.styles';
import CalendarFormIcon from '../../../../packages/common/shared-ui/src/icons/CalendarFormIcon';
import IssueFileUpload from './IssueFileUpload';
import ClearFormFieldIcon from '../../../../packages/common/shared-ui/src/icons/ClearFormFieldIcon';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom/model';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
const IssueFormField = ({
  intl,
  field,
  handleField,
  bulk,
  changeParentIssue,
  parentIssueOptions,
  modal,
  handleFocusField,
}) => {
  const issueCreateEditClasses = issueFormFieldStyles();
  const [parentIssue, setParentIssue] = useState('');
  const [singleField, setSingleField] = useState(false);
  const [fieldError, setFieldError] = useState(null);

  useEffect(() => {
    if (field.fieldId === 'description' || field.fieldId === 'notes') {
      setSingleField(true);
    }
    if (field.fieldId === 'parentIssue') {
      setParentIssue({ ...field, valueId: field.valueId, valueName: field.valueName });
    }
  }, [field]);

  const handleNumberField = useCallback(
    (event, field) => {
      const reg = new RegExp(/^[0-9,.]*$/);
      if (reg.test(event.target.value)) {
        handleField(event.target.value, field);
      }
    },
    [handleField],
  );
  const validateUrl = useCallback((value, field) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    );
    value !== '' && field === 'link' && !urlPattern.test(value) && setFieldError(true);
    value !== '' && field === 'link' && urlPattern.test(value) && setFieldError(false);
  }, []);
  return (
    <Grid
      container
      sx={{ marginTop: singleField ? 0 : '16px' }}
      alignItems={'center'}
      flexWrap={'nowrap'}
      position={'relative'}
    >
      <FormControl
        required={field.fieldDefinition.isRequired}
        key={field.fieldId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          alignItems: singleField ? 'flex-start' : 'center',
        }}
      >
        <InputLabel className={issueCreateEditClasses.selectLabel} sx={{ marginTop: singleField ? '8px' : 0 }}>
          <Typography variant="h5" color={'#41424E'}>
            {field.custom
              ? `${field.fieldDefinition.fieldName}:`
              : `${intl.formatMessage({
                  id: `issue_page_${field.fieldDefinition.fieldName.replaceAll(' ', '_').toLowerCase()}`,
                })}:`}
          </Typography>
        </InputLabel>
        {(field.fieldDefinition.fieldFormat === 'float' ||
          field.fieldDefinition.fieldFormat === 'acl_percent' ||
          field.fieldDefinition.fieldFormat === 'int') && (
          <TextField
            onFocus={event => handleFocusField(event, field)}
            inputProps={{ maxLength: field.fieldDefinition.maxLength, minLength: field.fieldDefinition.minLength }}
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            error={field.error}
            required={field.fieldDefinition.isRequired}
            variant="outlined"
            sx={{ width: 250 }}
            className={issueCreateEditClasses.textField}
            placeholder={intl.formatMessage({ id: 'enter' })}
            //defaultValue={field.valueName ? field.valueName : ''}
            value={field.valueName ? field.valueName : ''}
            onChange={event => handleNumberField(event, field)}
          ></TextField>
        )}
        {(field.fieldDefinition.fieldId === 'description' || field.fieldDefinition.fieldFormat === 'text') && (
          <Grid container flexDirection={'column'}>
            <Editor
              init={{
                skin: false,
                content_css: false,
                height: 200,
                menubar: false,
                plugins: field.fieldDefinition.fieldId === 'description' ? 'image autolink' : '',
                toolbar: 'formatselect | bold italic backcolor',
                link_default_target: '_blank',
                branding: false,
              }}
              value={field.valueName ? field.valueName : ''}
              onEditorChange={content => handleField(content, field)}
            />
          </Grid>
        )}
        {field.fieldDefinition.fieldId !== 'description' &&
          (field.fieldDefinition.fieldFormat === 'string' || field.fieldDefinition.fieldFormat === 'link') && (
            <TextField
              onBlur={event => validateUrl(event.target.value, field.fieldDefinition.fieldFormat)}
              disabled={field.deleted || field.fieldDefinition.isReadonly}
              error={field.error}
              multiline={singleField}
              rows={singleField ? 3 : ''}
              required={field.fieldDefinition.isRequired}
              sx={{ width: 353 }}
              variant="outlined"
              className={issueCreateEditClasses.textField}
              placeholder={intl.formatMessage({ id: 'enter' })}
              defaultValue={field.valueName ? field.valueName : ''}
              onChange={event => handleField(event, field)}
            ></TextField>
          )}
        {field.fieldDefinition.fieldFormat === 'bool' && (
          <RadioGroup
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            required={field.fieldDefinition.isRequired}
            className={issueCreateEditClasses.booleanWrap}
            onChange={(event, value) => handleField(value, field)}
            value={field.valueId}
          >
            <FormControlLabel
              className={issueCreateEditClasses.booleanLabel}
              value={true}
              checked={field.valueId !== '' && field.valueId === 'true'}
              control={
                <Radio
                  disableRipple
                  icon={
                    field.error ? (
                      <CheckboxIcon color={'#E03737'} width="12" height="12" viewBox="0 0 12 12" />
                    ) : (
                      <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                    )
                  }
                  checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                />
              }
              label={
                <Typography variant="h5">
                  <FormattedMessage id="yes" />
                </Typography>
              }
            />
            <FormControlLabel
              className={issueCreateEditClasses.booleanLabel}
              value={false}
              checked={field.valueId !== '' && field.valueId === 'false'}
              control={
                <Radio
                  disableRipple
                  icon={
                    field.error ? (
                      <CheckboxIcon color={'#E03737'} width="12" height="12" viewBox="0 0 12 12" />
                    ) : (
                      <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                    )
                  }
                  checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                />
              }
              label={
                <Typography variant="h5">
                  <FormattedMessage id="no" />
                </Typography>
              }
            />
          </RadioGroup>
        )}
        {field.fieldId === 'parentIssue' && (
          <Autocomplete
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            noOptionsText={intl.formatMessage({ id: 'not_found' })}
            filterOptions={x => x}
            required={field.fieldDefinition.isRequired}
            onChange={(event, newValue) => handleField(newValue, field)}
            className={issueCreateEditClasses.filterSelect}
            disablePortal
            options={parentIssueOptions}
            sx={{ width: 253 }}
            disableClearable={bulk || !modal}
            renderOption={(props, option) => {
              return (
                <MenuItem
                  {...props}
                  selected={option.valueId === field.valueId}
                  key={option.valueId}
                  value={option.valueId + option.valueName}
                >
                  {option.valueName}
                </MenuItem>
              );
            }}
            value={parentIssue ? parentIssue : ''}
            getOptionLabel={option => option.valueName || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
            renderInput={params => (
              <TextField
                {...params}
                error={field.error}
                onChange={ev => changeParentIssue(ev.target.value)}
                placeholder={intl.formatMessage({ id: 'choose' })}
              />
            )}
            popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
          />
        )}
        {field.fieldDefinition.values && (
          <Autocomplete
            noOptionsText={intl.formatMessage({ id: 'not_found' })}
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            required={field.fieldDefinition.isRequired}
            onChange={(event, newValue) => handleField(newValue, field)}
            className={issueCreateEditClasses.filterSelect}
            disablePortal
            options={field.fieldDefinition.values}
            sx={{ width: 253 }}
            disableClearable={bulk}
            renderOption={(props, option) => {
              return (
                <MenuItem
                  {...props}
                  key={option.valueId + option.valueName}
                  value={option.valueId}
                  selected={field.valueId && option.valueId.toString() === field.valueId.toString()}
                >
                  {option.valueName}
                </MenuItem>
              );
            }}
            defaultValue={field.fieldDefinition.values.find(item => item.isChecked)}
            //value={field.fieldDefinition.values.find(item => item.isChecked)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={option => option.valueName || ''}
            PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
            renderInput={params => (
              <TextField {...params} error={field.error} placeholder={intl.formatMessage({ id: 'choose' })} />
            )}
            popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
          />
        )}
        {field.fieldDefinition.fieldFormat === 'date' && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              required={field.fieldDefinition.isRequired}
              disabled={field.deleted || field.fieldDefinition.isReadonly}
              format={'DD.MM.YYYY'}
              components={{
                OpenPickerIcon: ({ ...props }) => <CalendarFormIcon {...props} />,
                LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                RightArrowButton: componentProps => (
                  <IconButton disableRipple {...componentProps}>
                    {componentProps.children}
                  </IconButton>
                ),
                LeftArrowButton: componentProps => (
                  <IconButton disableRipple {...componentProps}>
                    {componentProps.children}
                  </IconButton>
                ),
              }}
              renderInput={params => (
                <TextField {...params} error={field.error} placeholder={intl.formatMessage({ id: 'enter' })} />
              )}
              className={issueCreateEditClasses.datePicker}
              value={field.valueName}
              onChange={value => handleField(value, field)}
              autoFocus={true}
            />
          </LocalizationProvider>
        )}
        {field.fieldDefinition.fieldFormat === 'attachment' && (
          <IssueFileUpload handleUpload={value => handleField(value, field)} single={true}></IssueFileUpload>
        )}
      </FormControl>
      {bulk && !field.fieldDefinition.isRequired && (
        <Tooltip
          PopperProps={{ className: issueCreateEditClasses.tooltipForm }}
          placement="top-start"
          title={intl.formatMessage({ id: 'issue_clear_tooltip' })}
        >
          <IconButton
            onClick={value => handleField(value, field, true)}
            disableRipple
            sx={{ padding: 0, marginLeft: '2px' }}
          >
            <ClearFormFieldIcon color={field.deleted ? '#E4E4EF' : '#7174AC'} />
          </IconButton>
        </Tooltip>
      )}
      {fieldError && (
        <Box sx={{ position: 'absolute', bottom: '-17px', left: '205px' }}>
          <Typography variant="h5" color={'#E03737'}>
            {intl.formatMessage({ id: 'issue_page_error_url_error' })}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default injectIntl(IssueFormField);
