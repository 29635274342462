import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import CustomFilter from 'Common/shared-ui/src/components/CustomFilter';
import DragDropLists from 'Common/shared-ui/src/components/DragDropLists/DragDropLists';
import useStyles from './IssuesModal.styles';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import gridFilterModal from '../../GridFiltersModal/GridFilterModal.styles';

const IssuesModal = ({
  timesheetsColumns,
  isModalOpen,
  closeModal,
  allFilters,
  setChosenFilters,
  chosenFilters,
  updateTable,
  currentType,
  setQuery,
  sortModel,
  query,
  intl,
}) => {
  const classes = useStyles();
  const popperAnchorEl = useRef();
  const location = useLocation();
  dayjs.extend(weekday);
  const modalClasses = gridFilterModal();

  const [isClearFilter, setIsClearFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [modalSessionParams, setModalSessionParams] = useState({
    chosenFirstStepItems: {},
    availableFields: query.fields
      ? allFilters.fields.filter(field => {
          return (
            query.fields?.split(';').filter(chosenField => {
              return chosenField === field.fieldId;
            }).length === 0
          );
        })
      : allFilters.fields.filter(field => !field.isChecked),
    selectedFields: query.fields
      ? allFilters.fields.filter(field => {
          return (
            query.fields?.split(';').filter(chosenField => {
              return chosenField === field.fieldId;
            }).length !== 0
          );
        })
      : allFilters.fields.filter(field => field.isChecked).sort((a, b) => (a.position > b.position ? 1 : -1)),
    groups: query.groups || 0,
    sorts: 0,
    totals: query.totals
      ? [
          ...query.totals.split('$$').map(field => {
            return allFilters.totals.indexOf(allFilters.totals.find(total => total.fieldId === field));
          }),
        ]
      : [],
  });

  const setFilterValues = useCallback(newFirstStepChosenValues => {
    setModalSessionParams(prevState => ({
      ...prevState,
      chosenFirstStepItems: newFirstStepChosenValues,
    }));
    window.localStorage.setItem('storageChosenItems', JSON.stringify(newFirstStepChosenValues));
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalSessionParams({
      chosenFirstStepItems: {},
      availableFields: query.fields
        ? allFilters.fields.filter(field => {
            return (
              query.fields?.split(';').filter(chosenField => {
                return chosenField === field.fieldId;
              }).length === 0
            );
          })
        : allFilters.fields.filter(field => !field.isChecked),
      selectedFields: query.fields
        ? allFilters.fields.filter(field => {
            return (
              query.fields?.split(';').filter(chosenField => {
                return chosenField === field.fieldId;
              }).length !== 0
            );
          })
        : allFilters.fields.filter(field => field.isChecked).sort((a, b) => (a.position > b.position ? 1 : -1)),
      groups: query.groups || 0,
      sorts: query.sorts || 0,
      totals: query.totals
        ? [
            ...query.totals.split('$$').map(field => {
              return allFilters.totals.indexOf(allFilters.totals.find(total => total.fieldId === field));
            }),
          ]
        : [],
    });
    return closeModal();
  }, [allFilters.fields, allFilters.totals, closeModal, query.fields, query.sorts, query.groups, query.totals]);

  const handleChangeGroup = useCallback(({ e, clear = false }) => {
    if (clear) {
      return setModalSessionParams(prevState => ({ ...prevState, groups: 0 }));
    }
    setModalSessionParams(prevState => ({ ...prevState, groups: e.target.value }));
  }, []);

  const handleChangeTotals = useCallback(e => {
    setModalSessionParams(prevState => ({ ...prevState, totals: e.target.value }));
  }, []);
  const handleChangeTreeView = useCallback(e => {
    if (e.target.checked) {
      const parentField = 'issue.parent_issue;asc';
      setModalSessionParams(prevState => ({ ...prevState, sorts: parentField }));
    } else {
      setModalSessionParams(prevState => ({ ...prevState, sorts: '' }));
    }
  }, []);
  const handleRemoveTotals = useCallback(e => {
    const value = e.currentTarget.attributes.value.value;
    return setModalSessionParams(prevState => {
      return {
        ...prevState,
        totals:
          prevState.totals.length === 1
            ? []
            : prevState.totals.filter(filterIdx => Number(filterIdx) !== Number(value)),
      };
    });
  }, []);
  const handleSaveOptions = useCallback(async () => {
    closeModal();
    const formattedFields = modalSessionParams.selectedFields.map(field => field.fieldId);
    const formattedTotals = modalSessionParams.totals.map(id => allFilters.totals[id]?.fieldId).filter(item => item);
    const formattedFilters = Object.values(modalSessionParams.chosenFirstStepItems)
      .filter(item => (item.type === 'btw' ? item.subLabels.length > 1 : item.subLabels.length))
      .reduce((acc, rec) => {
        if (rec.type === 'all') {
          return acc;
        }
        return [
          ...acc,
          `${rec.value};${rec.notParsedType};${rec.subLabels
            ?.map(subLabel => {
              if (!subLabel) {
                return;
              }
              return subLabel.valueId;
            })
            .join(rec.type !== 'btw' ? '||' : ';')}`,
        ];
      }, []);
    setQuery({
      ...query,
      page: 1,
      offset: 0,
      totals: formattedTotals.length > 0 ? formattedTotals.join('$$') : 'null',
      filters: formattedFilters.join('$$'),
      groups: modalSessionParams.groups !== 0 ? modalSessionParams.groups : '',
      sorts: modalSessionParams.sorts !== 0 ? modalSessionParams.sorts : '',
      fields: formattedFields.join('$$'),
    });
    updateTable({
      totals: formattedTotals.length > 0 ? formattedTotals.join('$$') : 'null',
      filters: formattedFilters.join('$$'),
      fields: formattedFields.join('$$'),
      [modalSessionParams.groups !== 0 ? 'groups' : null]: modalSessionParams.groups,
      [modalSessionParams.sorts !== 0 ? 'sorts' : null]: modalSessionParams.sorts,
    });
    setModalSessionParams(prevState => {
      let newValues = Object.values(prevState.chosenFirstStepItems)
        .filter(item => (item.type === 'btw' ? item.subLabels.length > 1 : item.subLabels.length))
        .map(item => ({ [item.value]: item }));
      newValues = Object.assign({}, ...newValues);
      return {
        ...prevState,
        chosenFirstStepItems: newValues,
      };
    });
    window.localStorage.setItem('storageChosenItems', JSON.stringify(modalSessionParams.chosenFirstStepItems));
  }, [
    allFilters.totals,
    closeModal,
    setModalSessionParams,
    modalSessionParams.chosenFirstStepItems,
    modalSessionParams.groups,
    modalSessionParams.selectedFields,
    modalSessionParams.totals,
    modalSessionParams.sorts,
    query,
    setQuery,
    updateTable,
  ]);

  const handleClearParams = useCallback(() => {
    setIsClearFilter(true);
    setModalSessionParams(prevState => ({
      ...prevState,
      chosenFirstStepItems: {},
      availableFields: query.fields
        ? allFilters.fields.filter(field => {
            return (
              query.fields?.split(';').filter(chosenField => {
                return chosenField === field.fieldId;
              }).length === 0
            );
          })
        : allFilters.fields.filter(field => !field.isChecked),
      selectedFields: query.fields
        ? allFilters.fields.filter(field => {
            return (
              query.fields?.split(';').filter(chosenField => {
                return chosenField === field.fieldId;
              }).length !== 0
            );
          })
        : allFilters.fields.filter(field => field.isChecked).sort((a, b) => (a.position > b.position ? 1 : -1)),
      groups: 0,
      sorts: 0,
      totals: [],
    }));
    // setQuery({ ...query, groups: null, filters: '' });
    setTimeout(() => setIsClearFilter(false), 500);
  }, [allFilters, query]);
  const renderCustomFilter = () => {
    if (query.project) {
      if (modalSessionParams.chosenFirstStepItems?.length > 0) {
        modalSessionParams.chosenFirstStepItems = modalSessionParams.chosenFirstStepItems?.filter(
          filter => filter.fieldId != 'issue.project',
        );
      }
      modalSessionParams.chosenFirstStepItems = {
        'issue.project': {
          label: getTranslatedText(intl, 'filter', 'issue.project', 'project'),
          value: 'issue.project',
          subLabels: [],
          fieldType: 'project',
          type: 'project',
          notParsedType: 'project',
          locked: true,
        },
        ...modalSessionParams.chosenFirstStepItems,
      };
      window.localStorage.setItem('storageChosenItems', JSON.stringify(modalSessionParams.chosenFirstStepItems));
    }
    return (
      <CustomFilter
        intl={intl}
        defaultStep={0}
        fixedAnchorEl={popperAnchorEl}
        firstStepItems={allFilters.filters.sort((a, b) => {
          const ruCollator = new Intl.Collator('ru-RU');
          const firstTranslatedValue = getTranslatedText(intl, 'filter', a.fieldId, a.fieldName).toLowerCase();
          const secondTranslatedValue = getTranslatedText(intl, 'filter', b.fieldId, b.fieldName).toLowerCase();
          return ruCollator.compare(firstTranslatedValue, secondTranslatedValue);
        })}
        chosenFirstStepItems={modalSessionParams.chosenFirstStepItems}
        setValues={setFilterValues}
        isClear={isClearFilter}
      />
    );
  };
  useEffect(() => {
    if (location.state?.isGetDataFromStorage && !query.savedQueryId) {
      const chosenItemsFromStorage = JSON.parse(window.localStorage.getItem('storageChosenItems'));
      setModalSessionParams(prevState => {
        return {
          ...prevState,
          chosenFirstStepItems: chosenItemsFromStorage,
        };
      });
    }
    if (
      currentType !== 1 &&
      query.fields &&
      query.fields.split('$$').length > 0 &&
      modalSessionParams.selectedFields.length === 0
    ) {
      query.fields.split('$$').map(selectedField => {
        const currentField = modalSessionParams.availableFields.find(field => field.fieldId === selectedField);
        if (currentField) {
          setModalSessionParams(prevState => ({
            ...prevState,
            availableFields: prevState.availableFields.filter(field => field.fieldId !== currentField.fieldId),
            selectedFields: [...prevState.selectedFields, currentField],
          }));
        }
      });
    }
    if (!query.limit && !query.offset) {
      return handleClearParams();
    }
    if (query.filters && query.filters.split(';').length > 0) {
      if (isClearFilter) {
        return;
      }
      let newChosenItems = JSON.parse(window.localStorage.getItem('storageChosenItems'));
      if (query.savedQueryId || loading) {
        newChosenItems = query.filters.split('$$').reduce((acc, rec) => {
          const currentFilter = allFilters.filters.find(filter => filter.fieldId === rec.split(';')[0]);
          const splittedValues = rec.split(';');
          const typeFirstLetter = splittedValues.length > 1 ? splittedValues[1][0] : '';
          const regex = /^[a-z]$/i;
          const formattedType =
            typeFirstLetter === typeFirstLetter.toUpperCase()
              ? splittedValues.slice(2).length === 1
                ? 'eq'
                : 'btw'
              : splittedValues[1];
          let currentSubLabels = (
            splittedValues.length === 1
              ? splittedValues[2].split('||')
              : splittedValues.slice(2).reduce((acc, item) => {
                  return [...acc, ...item.split('||')];
                }, [])
          ).reduce(
            (acc, rec) => {
              if (!rec) {
                return {
                  subLabels: [null],
                };
              }
              return {
                ...acc,
                subLabels:
                  rec === 'null' || rec === 'all'
                    ? [null]
                    : [
                        ...acc.subLabels,
                        {
                          valueName: getTranslatedText(intl, '', currentFilter.fieldId, currentFilter.fieldName),
                          valueId: rec,
                          [(!regex.test(typeFirstLetter) || splittedValues[1] === 't-') && 'value']: dayjs().diff(
                            dayjs(rec),
                            'days',
                          ),
                        },
                      ],
              };
            },
            { subLabels: [] },
          );
          return {
            ...acc,
            [currentFilter.fieldId]: {
              id: currentFilter.fieldId,
              label: getTranslatedText(intl, 'filter', currentFilter.fieldId, currentFilter.fieldName),
              value: currentFilter.fieldId,
              type: formattedType,
              notParsedType: rec.split(';')[1],
              valueName: currentFilter.fieldName,
              fieldType: currentFilter.fieldType,
              ...currentSubLabels,
            },
          };
        }, {});
        window.localStorage.setItem('storageChosenItems', JSON.stringify(newChosenItems));
      }
      if (loading) {
        setModalSessionParams(prevState => {
          return {
            ...prevState,
            chosenFirstStepItems: newChosenItems,
          };
        });
      }
      setLoading(false);
    }
  }, [
    isClearFilter,
    allFilters,
    currentType,
    handleClearParams,
    intl,
    loading,
    setLoading,
    location.state,
    modalSessionParams.availableFields,
    modalSessionParams.selectedFields,
    modalSessionParams.chosenFirstStepItems,
    query.fields,
    query.filters,
    query.limit,
    query.offset,
    query.savedQueryId,
    query.project,
    setQuery,
  ]);

  const { current: menuProps } = useRef({
    className: classes.totalsItems,
    day: 1,
  });

  return (
    <Dialog open={isModalOpen} className={modalClasses.modalRoot} fullWidth={true}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={8.5}>
        <Typography variant="h1">
          <FormattedMessage id="parameters_text" />
        </Typography>
        <IconButton disableRipple onClick={handleCloseModal} value="close">
          <Box width={24} height={24}>
            <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
          </Box>
        </IconButton>
      </Grid>
      <Grid container direction="column" overflow={'hidden'} flexWrap={'nowrap'}>
        <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5} marginBottom={5}>
          <Grid item xs={5}>
            <Typography variant="h5">
              <FormattedMessage id="group" />
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Select
              labelId="options_group"
              variant="outlined"
              onChange={e => handleChangeGroup({ e })}
              value={modalSessionParams.groups}
              IconComponent={() => (
                <Box className={classes.groupIcon} display={modalSessionParams.groups !== 0 ? 'none' : ''}>
                  <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                </Box>
              )}
              endAdornment={
                <IconButton
                  disableRipple
                  sx={{ display: modalSessionParams.groups === 0 ? 'none' : '' }}
                  onClick={() => handleChangeGroup({ clear: true })}
                >
                  <ClearIcon viewBox="0 0 24 24" color="primary" />
                </IconButton>
              }
              renderValue={value => {
                const currentGroupObj = allFilters.groups.find(item => item.fieldId === value);
                if (value === modalSessionParams.groups) {
                  return currentGroupObj
                    ? getTranslatedText(intl, 'filter', currentGroupObj.fieldId, currentGroupObj.fieldName)
                    : getTranslatedText(intl, '', 'choose', 'Choose');
                }
              }}
              defaultValue={0}
              MenuProps={{ className: classes.optionsGroupMenu }}
              color="secondary"
            >
              {allFilters.groups
                .sort((a, b) => {
                  const ruCollator = new Intl.Collator('ru-RU');
                  const firstTranslatedValue = getTranslatedText(intl, 'filter', a.fieldId, a.fieldName).toLowerCase();
                  const secondTranslatedValue = getTranslatedText(intl, 'filter', b.fieldId, b.fieldName).toLowerCase();
                  return ruCollator.compare(firstTranslatedValue, secondTranslatedValue);
                })
                .map(group => (
                  <MenuItem value={group.fieldId} key={group.fieldId}>
                    <Typography variant="h5" color="#212346">
                      {getTranslatedText(intl, 'filter', group.fieldId, group.fieldName)}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5} marginBottom={5}>
          <Grid item xs={5}>
            <Typography variant="h5">
              <FormattedMessage id="filter" />
            </Typography>
          </Grid>
          <Grid item xs={7} ref={popperAnchorEl}>
            {renderCustomFilter()}
          </Grid>
        </Grid>
        <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5}>
          <Grid item xs={5}>
            <Typography variant="h5">
              <FormattedMessage id="spent_time_totals" />
            </Typography>
          </Grid>
          <Grid item display="flex" xs={7}>
            <Select
              className={classes.totalsSelect}
              multiple
              value={modalSessionParams.totals}
              onChange={handleChangeTotals}
              color="secondary"
              MenuProps={menuProps}
              renderValue={selected => (
                <Box display="flex" flexWrap="nowrap" alignItems="center">
                  {selected.map(value => {
                    if (value < 0) {
                      return;
                    }
                    const chipLabel = getTranslatedText(
                      intl,
                      'filter',
                      allFilters.totals[value].fieldId,
                      allFilters.totals[value].fieldName,
                    );
                    return (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.chosenTotals}
                        key={value}
                      >
                        <Chip
                          label={chipLabel}
                          className={classes.totalsChip}
                          value={value}
                          deleteIcon={
                            <ClearIcon
                              width={16}
                              height={16}
                              viewBox="0 0 24 24"
                              value={value}
                              onMouseDown={event => event.stopPropagation()}
                              className={classes.totalsRemove}
                            />
                          }
                          onDelete={handleRemoveTotals}
                        />
                        {/*<IconButton*/}
                        {/*  value={value}*/}
                        {/*  disableRipple*/}
                        {/*  className={classes.totalsRemove}*/}
                        {/*  onClick={handleRemoveTotals}*/}
                        {/*>*/}
                        {/*  <Box width={16} height={16} display="flex" alignItems="center" justifyContent="center">*/}
                        {/*    <ClearIcon viewBox="0 0 24 24" color="primary" />*/}
                        {/*  </Box>*/}
                        {/*</IconButton>*/}
                      </Box>
                    );
                  })}
                </Box>
              )}
            >
              {allFilters.totals.map((field, index) => {
                return (
                  <MenuItem key={field.fieldId} value={index} className={classes.menuItem}>
                    <Typography variant="h5" textTransform="capitalize">
                      {getTranslatedText(intl, 'filter', field.fieldId, field.fieldName)}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5}>
          <Grid item xs={5}>
            <Typography variant="h5">
              <FormattedMessage id="spent_time_tree_view" />
            </Typography>
          </Grid>
          <Grid item display="flex" xs={7}>
            <Checkbox
              disableRipple
              checked={modalSessionParams.sorts?.length > 1 && modalSessionParams.sorts?.includes('issue.parent_issue')}
              onChange={handleChangeTreeView}
              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
            />
          </Grid>
        </Grid>
        <DragDropLists
          intl={intl}
          firstListOptions={modalSessionParams.availableFields}
          secondListOptions={modalSessionParams.selectedFields}
          setParentState={setModalSessionParams}
          getOptionLabel={option =>
            `${option.fieldId};${getTranslatedText(intl, 'filter', option.fieldId, option.fieldName)}`
          }
        />
        <Grid container justifyContent="center" marginTop={5}>
          <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearParams}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
            </Typography>
          </Button>
          <Box marginLeft={1}>
            <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveOptions}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="save_text" defaultMessage="Save" />
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default IssuesModal;
