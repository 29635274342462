import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  customPopper: {
    '& + .MuiAutocomplete-popper': {
      zIndex: 1,
      position: 'static!important',
      transform: 'none!important',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
  fieldsList: {
    '&.MuiPaper-root': {
      //maxHeight: 324,
      //minHeight: 324,
      // overflow: 'auto',
      boxShadow: 'none',
      overflow: 'auto',
      borderRadius: theme.spacing(0, 0, 4, 4),
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& .MuiAutocomplete-option': {
        padding: 0,
      },
      '& .MuiAutocomplete-listbox': {
        padding: 0,
        //height: 324,
        maxHeight: 'none',
      },
    },
    //'&.MuiPaper-root::-webkit-scrollbar': {
    //  display: 'none',
    //},
  },
  optionsFilterInput: {
    '&.MuiTextField-root': {
      '& .MuiInputBase-root': {
        padding: 0,
        borderRadius: theme.spacing(4, 4, 0, 0),
        backgroundColor: '#ffffff !important',
        borderBottom: '1px solid #E4E4EF',
        '& input': {
          padding: theme.spacing(2, 2),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&:not(.Mui-error):hover': {
          borderBottom: '1px solid #E4E4EF',
        },
      },
    },
  },
  fieldsListItem: {
    height: 36,
    padding: theme.spacing(2, 2),
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#F1F2F8',
    },
    '& .MuiAutocomplete-option': {
      '&.Mui-focused': {
        backgroundColor: 'red',
      },
    },
  },
  fieldsListDisabledItem: {
    height: 36,
    padding: theme.spacing(2, 2),
    cursor: 'pointer',
    userSelect: 'none',
    color: '#7174AC',
    // '&:hover': {
    //   backgroundColor: '#F1F2F8',
    // },
    '& .MuiAutocomplete-option': {
      '&.Mui-focused': {
        backgroundColor: 'red',
      },
    },
  },
}));

export default useStyles;
