import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './components/app/redux/index';

import AppContainer from './components/app/AppContainer.js';
import ThemeSetup from './components/app/ThemeSetup';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { LicenseInfo } from '@mui/x-license-pro';
//document.getElementById('x-lance-root').oncontextmenu = () => false;
const root = ReactDOM.createRoot(document.getElementById('x-lance-root'));
LicenseInfo.setLicenseKey(
  '10782664b2a14c5ee2d689b47d6dba2aTz05NjAzNSxFPTE3NTUxNTExNjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);
const RootComponent = () => {
  return (
    <ReduxProvider store={store}>
      <Router>
        <ThemeSetup>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AppContainer />
          </QueryParamProvider>
        </ThemeSetup>
      </Router>
    </ReduxProvider>
  );
};

root.render(<RootComponent />);
