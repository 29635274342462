import { makeStyles } from '@mui/styles';

const ganttIssueRelatedModalStyles = makeStyles(theme => ({
  modalRoot: {
    '& .MuiPaper-root.MuiDialog-paper': {
      overflow: 'visible',
      padding: '30px 20px',
      maxWidth: '600px',
      backgroundColor: '#F1F2F8',
      borderRadius: '16px',
      maxHeight: 'calc(100% - 130px)',
      margin: 0,
      '& .MuiFormControl-root.MuiTextField-root.formTextField': {
        width: '200px',
        '& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input': {
          backgroundColor: '#fff',
          borderRadius: '16px',
        },
      },
    },
  },
}));

export default ganttIssueRelatedModalStyles;
