import { LOCALES } from './locales';

//
export default {
  [LOCALES.ENGLISH]: {
    // GLOBAL
    me: 'Me',
    save_text: 'Save',
    save_text_and_go: 'Save and continue',
    add_text: 'Add',
    apply_text: 'Apply',
    parameters_text: 'Parameters',
    comments: 'Comment',
    yes: 'Yes',
    no: 'No',
    true: 'Yes',
    false: 'No',
    cancel: 'Cancel',
    save: 'Save',
    save_changes: 'Save changes?',
    my_account: 'My account',
    logout: 'Logout',
    hour: 'h',
    hours: 'Hours',
    enter: 'Enter',
    choose: 'Choose',
    filter: 'Filter',
    signature: 'signature',
    plan_type: 'plan type',
    group: 'Group',
    no_results: 'No results found',
    days: 'Days',
    table_error: 'Time tracking is not possible',
    spent_time_error: 'Tracking is not possible',
    not_found: 'Not found',
    selected_fields: 'Selected fields',
    available_fields: 'Available fields',
    back_to_main_page: 'Back to main page',
    search_options: 'Loading...',
    edit_text: 'Edit',
    delete_text: 'Delete',
    copy_text: 'Copy',

    // ERRORS
    error: 'Error',
    error_404: 'Page not found',
    error_403: 'No access',
    error_500: 'Unexpected error',
    error_413: 'Request entity too large',
    // PAGE TITLE
    page_title_login: 'Login',
    page_title_spreadsheet: 'Spreadsheet',
    page_title_projects: 'Projects',
    page_title_projects_settings: 'Projects settings',
    page_title_project_reports: 'Reports',
    page_title_production_calendar: 'Production calendar',
    page_title_spentTime: 'Spent time',
    page_title_spentTime_bulk: 'Spent time bulk',
    page_title_user_settings: 'Settings',
    page_title_resource_plan: 'Resource plan',
    page_title_confirmations: 'Confirmations',

    // MENU
    menu_item_projects: 'Projects',
    menu_item_projects_settings: 'Projects settings',
    menu_item_project_reports: 'Reports',
    menu_item_actions: 'Activity',
    menu_item_tasks: 'Issues',
    menu_item_work_costs: 'Spent time',
    menu_item_work_costs_my: 'My spent time',
    menu_item_work_costs_approval: 'Spent time on approval',
    menu_item_work_costs_fact: 'Actual approval on spent time',
    menu_item_diagram: 'Gantt',
    menu_item_confirmations: 'Confirmations',
    // menu_item_news: 'News',
    menu_item_art: 'ART',
    menu_item_spreadsheet: 'Timesheet',
    menu_item_resource_plan: 'Resource plan',
    menu_item_agile: 'Agile',
    menu_item_kanban: 'Kanban board',
    menu_item_administration: 'Administration',
    menu_item_administration_projects: 'Projects',
    menu_item_administration_users: 'Users',
    menu_item_administration_groups: 'Groups',
    menu_item_administration_permissions: 'Roles and permissions',
    menu_item_administration_trackers: 'Issue type',
    menu_item_administration_task_statuses: 'Task statuses',
    menu_item_administration_workflows: 'Workflows',
    menu_item_administration_custom_fields: 'Custom fields',
    menu_item_administration_enumerations: 'Enumerations',
    menu_item_administration_calendar: 'Production calendar',
    menu_item_administration_project_reports: 'Reports',
    menu_item_administration_settings: 'Settings',
    menu_item_administration_resourcePlan: 'Resource plan settings',
    menu_item_administration_calculationTypes: 'Calculation types',
    menu_item_administration_contractStages: 'Contract stages',
    menu_item_administration_confirmation_settings: 'Confirmation settings',
    menu_item_administration_gantt_settings: 'gantt settings',

    //  AUTH PAGE
    auth_page_title: 'Authorization',
    auth_page_login_input_text: 'Login',
    auth_page_password_input_text: 'Password',
    auth_page_login_label_text: 'Enter your e-mail',
    auth_page_password_label_text: 'Enter the password',
    auth_page_button_text: 'Login',
    auth_page_remember_checkbox_text: 'Remember me',
    auth_page_forget_pass_text: 'Forgot password?',
    auth_page_info_text: 'For project, resource and financial management',
    auth_page_language_ru_text: 'Russian',
    select_language_label_text: 'Language',
    // SPREADSHEET PAGE
    spreadsheet_time_main: 'Worktime',
    spreadsheet_time_over: 'Overtime',
    spreadsheet_table_clear: 'Clear',
    spreadsheet_table_project: 'Project',
    spreadsheet_table_issues: 'Issues',
    spreadsheet_table_mon: 'Mon',
    spreadsheet_table_tue: 'Tue',
    spreadsheet_table_wed: 'Wed',
    spreadsheet_table_thu: 'Thu',
    spreadsheet_table_fri: 'Fri',
    spreadsheet_table_sat: 'Sat',
    spreadsheet_table_sun: 'Sun',
    spreadsheet_table_total: 'Total',
    spreadsheet_choose_project: 'Select project',
    spreadsheet_choose_task: 'Select task',
    spreadsheet_overtime: 'Downtime/Overtime',
    spreadsheet_week_sum: 'Total for the week',
    spreadsheet_issue_edit: 'Issue edit',
    spreadsheet_projects_null: 'Projects not found',
    spreadsheet_tooltip_hide_text: 'Hide string',
    spreadsheet_tooltip_hide_checkbox: 'Only filled in',
    // PRODUCTION CALENDAR
    prod_calendar_title: 'Production calendar',
    prod_calendar_hours_default: 'Number of working hours by default',
    prod_calendar_days_off: 'Days off',
    prod_calendar_date: 'Date',
    // SPENT TIME PAGE
    spent_time_type_details: 'Details',
    spent_time_type_summary: 'Summary',
    spent_time_add: 'Add labor costs',
    spent_time_save: 'Save request',
    spent_time_edit: 'Edit request',
    spent_time_delete: 'Delete request',
    spent_time_chosen_fields: 'Chosen fields',
    spent_time_available_fields: 'Available fields',
    spent_time_totals: 'Totals',
    // FILTERS
    filter_timesheet_activity: 'Activity',
    filter_timesheet_author: 'Author',
    filter_timesheet_comments: 'Comment',
    filter_timesheet_created_on: 'Created on',
    filter_timesheet_hours: 'Hours',
    filter_timesheet_count: 'Count',
    filter_timesheet_is_approved: 'Approved',
    filter_timesheet_issue: 'Issue',
    filter_timesheet_project: 'Project',
    filter_timesheet_spent_on: 'Date',
    filter_timesheet_user: 'User',
    filter_timesheet_costs: 'Costs',
    filter_fieldName: 'Issue type',
    filter_issue_subject: 'Issue subject',
    filter_issue_status: 'Issue status',
    filter_status: 'Status',
    filter_user_list: 'User',
    // ERRORS
    error_unauthorized: 'Invalid username or password',
    error_unknown: 'Unknown error, try again later',
    // SETTINGS
    settings_tasktype_type: 'Task type',
    settings_tasktype_placeholder: 'Field',
    settings_tasktype_before: 'Before',
    settings_tasktype_after: 'After',
    settings_tasktype_change_date: 'Change date',
    settings_tasktype_author: 'Change author',
    settings_task_header: 'Tasks',
    settings_task_name: 'All',
    settings_task_opened: 'Opened',
    settings_task_closed: 'Closed',
    settings_project_header: 'Projects',
    settings_project_name: 'Project',
    settings_project_role: 'Roles',
    settings_project_reg_date: 'Registrated',
    // RESOURCE PLAN
    Project: 'Project',
    Resource: 'Resource',
    resource_plan_settings: 'Resource plan settings',
    resplan_settings_choose: 'Choose',
    resplan_settings_contract_tracker: 'Contract issue type',
    resplan_settings_role_resource: 'Role resource',
    resplan_settings_dismissals_date: 'Dismissals date',
    resplan_settings_department: 'Settings department',
    resplan_settings_user_availiability: 'Availiability',
    resplan_settings_business_unit: 'Business unit',
    resplan_settings_tracker_header: 'Issue type Resource Plan',
    resplan_settings_employment_check: 'Allow Employment Check',
    resplan_settings_over_employment: 'Over Employment Prohibition',
    resplan_settings_access_role: 'Access roles',
    filter_resourcePlan_business_unit: 'Business-unit',
    filter_resourcePlan_date_to: 'Date to:',
    filter_resourcePlan_date_from: 'Date from:',
    filter_resourcePlan_department: 'Department',
    filter_resourcePlan_user_list: 'User',
    filter_resourcePlan_contract_issues: 'Contract / Issue',
    filter_resourcePlan_issues: 'Issue',
    filter_issues_project_list: 'Project',
    filter_issue_project_list: 'Project',
    filter_resourcePlan_project_list: 'Project',
    filter_resourcePlan_apply_tooltip: 'Apply filter',
    resPlan_loading_percent: 'Loading percent',
    resPlan_user: 'User',
    resPlan_project: 'Project',
    resPlan_contract: 'Contract',
    resPlan_issue: 'Issue',
    resPlan_date_to: 'Date to',
    resPlan_date_from: 'Date from',
    resplan_table_total: 'Total',
    resplan_table_perload: 'Downtime/Overtime',
    resPlan_tracker: 'Issue type',
    resPlan_monthly_distribution: 'Monthly distribution',
    resPlan_cost_fact: 'Cost fact',
    resPlan_labor_fact: 'Labor fact',
    resPlan_accept_saving: 'Accept',
    resPlan_cash_save_message: 'There are unsaved editings.',
    resPlan_cash_save_question: 'Save?',
    resPlan_is_loading: 'Data is loading...',
    resplan_no_data: 'Data not found',
    resPlan_dateFrom_error: 'Start date should be earlier than end date.',
    resPlan_dateTo_error: 'End date should be later than start date.',
    resPlan_emptyFields_error: 'Required fields should not be empty.',
    resPlan_dateFormat_error: 'Incorrect date format.',
    resPlan_no_issue_column: 'For creating new issue add column "Issue"',
    resPlan_add_new_issue: 'Create issue',
    // Users page
    filter_users_user: 'User',
    filter_users_first_name: 'Name',
    filter_users_last_name: 'Last name',
    filter_users_email: 'Email',
    filter_users_admin: 'Admin',
    filter_users_create_date: 'Create date',
    filter_users_last_connection: 'Last Connection',
    users_page_common: 'Common',
    users_page_rates: 'Rates',
    users_page_common_tab_information: 'Information',
    user_page_login: 'User',
    user_page_firstName: 'Name',
    user_page_lastName: 'Last name',
    user_page_field_email: 'E-mail',
    user_page_rates_calculation_type: 'Calculation Type',
    user_page_rates_rate: 'Rate',
    user_page_rates_date: 'Date from',
    user_page_rate_error: 'The rate is not unique by calculation type and date Valid From',
    // Pagination
    pagination_next: 'Next',
    pagination_previous: 'Previous',
    users_filter_Active: 'Active',
    users_filter_Registered: 'Registered',
    users_filter_Blocked: 'Blocked',
    // Issue page
    create_issue_agreement: 'Create an agreement',
    issue_clear_tooltip: 'Clear',
    issue_new_title: 'New task',
    issue_edit_title: 'Edit',
    issue_edit_bulk_title: 'Edit selected tasks',
    issue_add: 'Added',
    issue_updated: 'Updated',
    issue_page_estimated_value: 'Estimated value',
    issue_page_duration: 'Duration, days',
    issue_page_sprint: 'Sprint',
    issue_page_files_title: 'Files',
    issue_page_watchers_title: 'Watchers',
    issue_page_children_title: 'Sub tasks',
    issue_page_confirmations_title: 'confirmations',
    issue_page_confirmations_confirmation: 'confirmation',
    issue_page_confirmations_status: 'status',
    issue_page_confirmations_createdBy: 'createdBy',
    issue_page_confirmations_createdOn: 'createdOn',
    issue_page_related_title: 'Related',
    issue_page_contract_stages_title: 'Contract stages',
    issue_page_history_title: 'History',
    issue_page_children_subject: 'Issue subject',
    issue_page_children_issue_status: 'Issue status',
    issue_page_children_assigned: 'Assigned to',
    issue_page_children_start: 'Start date',
    issue_page_children_end: 'Due date',
    issue_page_watchers_placeholder: 'Type watcher',
    issue_page_related_task_label: 'Task',
    issue_page_related_task_delay: 'Delay',
    issue_page_related_task_days: 'days',
    issue_page_status: 'Status',
    issue_page_due_date: 'Due date',
    issue_page_estimated_time: 'Estimated time',
    issue_page_spent_time: 'Spent time',
    issue_page_project: 'Project',
    issue_page_tracker: 'Issue type',
    issue_page_subject: 'Subject',
    issue_page_issue_status: 'Status',
    issue_page_priority: 'Priority',
    issue_page_is_private: 'Private',
    issue_page_assignee: 'Assignee',
    issue_page_assigned_to: 'Assignee',
    issue_page_category: 'Category',
    issue_page_version: 'Target version',
    issue_page_parent_task: 'Parent task',
    issue_page_calculation_type: 'Calculation Type',
    issue_page_parent_issue: 'Parent task',
    issue_page_start_date: 'Start date',
    issue_page_end_date: 'Due date',
    issue_page_estimated_hours: 'Estimated time',
    issue_page_completion: '% Done',
    issue_page_description: 'Description',
    issue_page_plan_cost: 'Estimated value',
    issue_page_created: 'Created',
    issue_page_updated: 'Updated',
    issue_page_closed: 'Closed',
    issue_page_author: 'Author',
    issue_page_percent_done: 'Done, %',
    issue_page_target_version: 'Version',
    issue_page_notes: 'Notes',
    issue_page_error: 'Required fields should not be empty.',
    issue_related_error: 'Unable to establish a connection between tasks',
    issue_page_error_url_error: 'url must be an URL address',

    // Issue status select
    issue_page_copied_to: 'Copied to',
    issue_page_precedes: 'Follows',
    issue_page_blocked_by: 'Blocked by',
    issue_page_has_duplicate: 'Has duplicate',
    issue_page_copied_from: 'Copied from',
    issue_page_blocks: 'Blocks',
    issue_page_follows: 'Precedes',
    issue_page_is_duplicate_of: 'Is duplicate of',
    issue_page_relates: 'Related to',
    issue_page_related_to: 'Related to',
    // Issue form
    issue_project_label: 'Project',
    issue_tracker_label: 'Tracker',
    //Issue tabs
    issue_tab_history: 'History',
    issue_tab_contract_stages: 'Contract stages',
    //Issue history
    issue_history_file: 'File',
    issue_history_deleted: 'deleted',
    issue_history_value: 'Value',
    issue_history_of_parameter: 'of parameter',
    issue_history_added: 'added',
    issue_history_parameter: 'Parameter',
    issue_history_changed_to: 'changed to',
    issue_history_changed: 'changed',
    issue_history_changed_from: 'changed from',
    issue_history_to: 'to',
    //Contract stages
    contract_stages_trackers_title: 'Issues type with contract stages',
    contract_stages_customers_title: 'Field customer',
    contract_stages_roles_title: 'Access to contract stages',
    contract_stages_customer: 'Customer',
    contract_stages_field: 'Contract stages field',
    issue_contract_stage_customer: 'Customer',
    issue_contract_stage_author: 'Author',
    issue_contract_stage_stage: 'Contract stage',
    issue_contract_stage_start_date: 'Start date',
    issue_contract_stage_comment: 'Comment',
    //Agile
    agile_title: 'Agile',
    agile_create_board: 'Create board',
    agile_edit_board: 'Edit board',
    agile_delete_board: 'Delete board',
    agile_tasks_status: 'Tasks status',
    // Confirmation Settings
    confirm_settings_business_unit: 'Business unit',
    confirm_settings_initial_state_of_document_confirmation: 'Initial state of document confirmation',
    confirm_settings_initial_state_of_document_changes_confirmation: 'Initial state of document changes confirmation',
    confirm_settings_final_state_of_document_confiramtion: 'Final state of document confiramtion',
    confirm_settings_tracker_of_document_confirmation: 'Issue type of document confirmation',
    confirm_settings_tracker_of_document_changes_confirmation: 'Issue type of document changes confirmation',
    confirm_settings_send_emails: 'Send emails',
    confirm_settings_approval_role: 'Approval role',
    confirm_settings_cancellation_role: 'Cancellation role',
    confirmation_trackers_tracker: 'Issue type',
    confirmation_trackers_url: 'Url',
    confirmation_trackers_company_group: 'Company group',
    confirmation_trackers_group_one: 'Group 1',
    confirmation_trackers_group_two: 'Group 2',
    confirmation_trackers_group_three: 'Group 3',
    confirmation_trackers_url_error: 'url must be an URL address',
    confirmation_trackers_save_error: 'An agreement already exists for a given document and business unit',
    // Confirmation page
    confirmation_page_title: 'Confirmation settings',
    confirmation_page_created_by: 'Created by',
    confirmation_page_created_on: 'Created on',
    confirmation_page_status: 'Status',
    confirmation_page_canceled_by: 'Canceled by',
    confirmation_page_updated_on: 'Updated on',
    confirmation_page_updated_by: 'Updated by',
    confirmations_page_created_on: 'created on',
    confirmations_page_approver: 'approver',
    confirmations_page_is_initial: 'is initial',
    confirmations_page_updated_on: 'updated on',
    confirmation_page_report: 'Report',
    confirmation_page_project: 'project',
    filter_confirmation_project: 'project',
    filter_confirmation_confirmation: 'confirmation',
    filter_confirmation_status: 'status',
    filter_confirmation_unapprover: 'unapprover',
    filter_confirmation_documentType: 'document type',
    filter_confirmation_document: 'document',
    filter_confirmation_createdBy: 'created by',
    filter_confirmation_updatedBy: 'updated by',
    filter_confirmation_createdOn: 'created on',
    filter_confirmation_approver: 'approver',
    filter_confirmation_isInitial: 'is initial',
    filter_confirmation_report: 'report',
    filter_confirmation_updatedOn: 'updated on',
    confirmation_page_is_closed: 'Closed',
    confirmation_page_document_type: 'Document type',
    confirmation_page_documents: 'Documents',
    confirmation_page_field_name: 'Name',
    confirmation_page_field_origin_value: 'Origin value',
    confirmation_page_origin_reinit: 'Origin value',
    confirmation_page_field_renew_value: 'Renew_value',
    confirmation_document_button: 'Confirmation document',
    confirmation_reinit_button: 'Confirmation changes',
    confirmation_result_title: 'Confirmation result',
    confirmation_page_approver: 'approver',
    confirmation_page_result: 'result',
    confirmation_page_comment: 'comment',
    confirmation_page_result_approved: 'approved',
    confirmation_page_result_cancelled: 'cancelled',
    confirmation_result_save_error: 'Please indicate the reason for rejection in the comments',
    confirmation_result_save_result_error: 'result error',
    confirm_settings_intermediate_state_of_document_confirmation: 'intermediate state of document confirmation',
    confirm_settings_roles_that_can_initialize_confirmation: 'roles that can initialize confirmation',
    confirm_settings_roles_that_can_view_confirmations: 'roles that can view confirmations',
    confirmation_fix: 'update document',
    // Confirmations page
    confirmations_page_project: 'project',
    confirmations_page_confirmation: 'confirmation',
    confirmations_page_status: 'status',
    confirmations_page_trackers: 'Issue type',
    confirmations_page_unapprover: 'unapprover',
    confirmations_page_document_type: 'document type',
    confirmations_page_document: 'document',
    confirmations_page_created_by: 'created by',
    confirmations_page_updated_by: 'updated by',
    // Confirmations documents titles
    confirmation_documents_due_date: 'Due date',
    confirmation_documents_estimated_time: 'Estimated time',
    confirmation_documents_spent_time: 'Spent time',
    confirmation_documents_project: 'Project',
    confirmation_documents_tracker: 'Issue type',
    confirmation_documents_subject: 'Subject',
    confirmation_documents_priority: 'Priority',
    confirmation_documents_is_private: 'Private',
    confirmation_documents_assignee: 'Assignee',
    confirmation_documents_assigned_to: 'Assignee',
    confirmation_documents_category: 'Category',
    confirmation_documents_version: 'Target version',
    confirmation_documents_parent_task: 'Parent task',
    confirmation_documents_calculation_type: 'Calculation Type',
    confirmation_documents_parent_issue: 'Parent task',
    confirmation_documents_start_date: 'Start date',
    confirmation_documents_end_date: 'Due date',
    confirmation_documents_estimated_hours: 'Estimated time',
    confirmation_documents_completion: '% Done',
    confirmation_documents_description: 'Description',
    confirmation_documents_plan_cost: 'Estimated value',
    confirmation_documents_created: 'Created',
    confirmation_documents_updated: 'Updated',
    confirmation_documents_closed: 'Closed',
    confirmation_documents_author: 'Author',
    confirmation_documents_percent_done: 'Done, %',
    confirmation_documents_target_version: 'Version',
    confirmation_documents_notes: 'Notes',
    // Confirmation result
    confirmation_result_approved: 'approved',
    confirmation_result_rejected: 'rejected',
    filters_select_assigned: 'assigned',
    filters_select_tracker: 'tracker',
    filters_select_parent_issue: 'parent issue',
    filters_select_project: 'project',
    filters_select_confirmation: 'confirmation',
    filters_select_status: 'status',
    filters_select_trackers: 'Issue type',
    filters_select_unapprover: 'unapprover',
    filters_select_document_type: 'document type',
    filters_select_document: 'document',
    filters_select_created_by: 'created by',
    filters_select_updated_by: 'updated by',
    signature_subject: 'subject',
    signature_loading_percent: 'loading percent',
    signature_assigned: 'assigned',
    signature_estimated_hours: 'estimated hours',
    signature_duration: 'Duration, days',
    signature_done_ratio: 'done ratio',
    signature_extended_subject: 'extended subject',
    access_roles: 'access roles',
    context_menu_edit: 'edit',
    context_menu_move: 'move',
    context_menu_move: 'delete',
    context_menu_after: 'after',
  },
};
