import React from 'react';
import { SvgIcon } from '@mui/material';

function ZoomCancelIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_20121_60068)">
          <path
            d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.50003 3C6.08003 3 3.28003 5.64 3.03003 9H5.05003C5.30003 6.75 7.18003 5 9.50003 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.50003 14C9.33003 14 9.17003 13.97 9.00003 13.95V15.97C9.17003 15.99 9.33003 16 9.50003 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14Z"
            fill="#7174AC"
          />
          <path
            d="M6.47007 10.8201L4.00007 13.2901L1.53007 10.8201L0.820068 11.5301L3.29007 14.0001L0.820068 16.4701L1.53007 17.1801L4.00007 14.7101L6.47007 17.1801L7.18007 16.4701L4.71007 14.0001L7.18007 11.5301L6.47007 10.8201Z"
            fill="#7174AC"
          />
        </g>
        <defs>
          <clipPath id="clip0_20121_60068">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ZoomCancelIcon;
