import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import gridFilterModal from './GridFilterModal.styles';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIcon from '../../../packages/common/shared-ui/src/icons/DeleteIcon';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import DragDropLists from '../../../packages/common/shared-ui/src/components/DragDropLists';
import { getTranslatedText } from '../../../packages/common/utils/getTranslatedText';
import { useLocation } from 'react-router';
import ChevronIcon from '../../../packages/common/shared-ui/src/icons/ChevronIcon';

const GridFiltersModal = ({
  intl,
  open,
  data,
  selectedFilters,
  selectedSignatures,
  selectedPlanType,
  activeFields,
  handleSave,
  handleClose,
  handleClear,
}) => {
  const classes = gridFilterModal();
  const [modalData, setModalData] = useState(null);
  const [currentModalFilters, setCurrentModalFilters] = useState([]);
  const [currentModalFields, setCurrentModalFields] = useState({ availableFields: [], selectedFields: [] });
  const [currentModalSignatures, setCurrentModalSignatures] = useState([]);
  const [currentModalPlanType, setCurrentModalPlanType] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.search === '') {
      setCurrentModalFilters([]);
    }
  }, [location.search]);
  useEffect(() => {
    if (selectedFilters && selectedFilters.length > 0) {
      setCurrentModalFilters(selectedFilters);
    }
  }, [selectedFilters]);
  useEffect(() => {
    if (activeFields && activeFields.selectedFields && activeFields.availableFields) {
      setCurrentModalFields(activeFields);
    }
  }, [activeFields]);
  useEffect(() => {
    if (selectedSignatures && selectedSignatures.length > 0) {
      setCurrentModalSignatures(selectedSignatures);
    }
  }, [selectedSignatures]);
  useEffect(() => {
    if (selectedPlanType) {
      setCurrentModalPlanType(selectedPlanType.valueId);
    }
  }, [selectedPlanType]);
  useEffect(() => {
    if (data) {
      data.fields &&
        activeFields &&
        activeFields.selectedFields.length === 0 &&
        activeFields.availableFields.length === 0 &&
        setCurrentModalFields({
          availableFields: data.fields.filter(item => !item.isChecked),
          selectedFields: data.fields.filter(item => item.isChecked),
        });
      setModalData(data);
    }
  }, [activeFields, data]);

  const handleSaveButton = useCallback(() => {
    const data = {
      filters: currentModalFilters,
      fields: currentModalFields,
      signatures: currentModalSignatures,
      planType: currentModalPlanType,
    };
    handleSave(data);
  }, [currentModalFilters, currentModalFields, currentModalSignatures, currentModalPlanType, handleSave]);

  const handleClearButton = useCallback(() => {
    //const values = {
    //  planType: selectedPlanType.valueId,
    //  signatures: [],
    //  filters: [],
    //  fields: {
    //    availableFields: data.fields.filter(item => !item.isChecked),
    //    selectedFields: data.fields.filter(item => item.isChecked),
    //  },
    //};
    setCurrentModalFilters([]);
    setCurrentModalFields({
      availableFields: data.fields.filter(item => !item.isChecked),
      selectedFields: data.fields.filter(item => item.isChecked),
    });
    setCurrentModalSignatures([]);
    selectedPlanType && setCurrentModalPlanType(selectedPlanType.valueId ? selectedPlanType.valueId : '');
    //handleClear(values);
  }, [data.fields, selectedPlanType]);

  const handleCloseButton = useCallback(() => {
    setCurrentModalFilters(selectedFilters);
    selectedPlanType && setCurrentModalPlanType(selectedPlanType.valueId);
    setCurrentModalFields({
      availableFields: data.fields.filter(item => !item.isChecked),
      selectedFields: data.fields.filter(item => item.isChecked),
    });
    setCurrentModalSignatures(selectedSignatures);
    handleClose();
  }, [data.fields, handleClose, selectedFilters, selectedPlanType, selectedSignatures]);

  const handleSelectFilters = useCallback(value => {
    setCurrentModalFilters(value);
  }, []);
  const handleSelectSignatures = useCallback(value => {
    setCurrentModalSignatures(value);
  }, []);
  const handleSelectPlanType = useCallback(value => {
    setCurrentModalPlanType(value);
  }, []);
  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth={true}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={8.5}>
        <Typography variant="h1">
          <FormattedMessage id="parameters_text" />
        </Typography>
        <IconButton disableRipple value="close" onClick={handleCloseButton}>
          <Box width={24} height={24}>
            <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
          </Box>
        </IconButton>
      </Grid>
      <Grid container direction="column" overflow={'hidden'} flexWrap={'nowrap'}>
        {/*        {modalData && modalData.groups && (
          <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5} marginBottom={5}>
            <Grid item xs={5}>
              <Typography variant="h5">
                <FormattedMessage id="group" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                variant="outlined"
                onChange={e => handleChangeGroup(e)}
                //value={modalSessionParams.groups}
                IconComponent={() => (
                  <Box className={classes.groupIcon}>
                    <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                  </Box>
                )}
                endAdornment={
                  <IconButton
                    disableRipple
                    //sx={{ display: modalSessionParams.groups === 0 ? 'none' : '' }}
                    //onClick={() => handleChangeGroup({ clear: true })}
                  >
                    <ClearIcon viewBox="0 0 24 24" color="primary" />
                  </IconButton>
                }
                MenuProps={{ className: classes.optionsGroupMenu }}
                color="secondary"
              >
                {modalData.groups.map(item => (
                  <MenuItem value={item.fieldId} key={item.fieldId}>
                    <Typography variant="h5" color="#212346">
                      {item.fieldName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}*/}
        {modalData && modalData.filters && (
          <Grid container paddingLeft={3.5} height={'100%'} alignItems={'center'}>
            <Grid item xs={5}>
              <Typography variant="h5">
                <FormattedMessage id="filter" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                className={classes.multipleAutocomplete}
                multiple
                disableClearable
                //open={true}
                onChange={(event, newValue) => handleSelectFilters(newValue)}
                options={modalData.filters}
                disableCloseOnSelect
                disablePortal
                value={currentModalFilters}
                isOptionEqualToValue={(option, value) => option.fieldId === value.fieldId}
                getOptionLabel={option => option.fieldName}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.fieldId}>
                    <Checkbox
                      icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                      checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {intl.formatMessage({
                      id: `filters_select_${option.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                    })}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                      className={classes.chip}
                      variant="outlined"
                      label={intl.formatMessage({
                        id: `filters_select_${option.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                      })}
                    />
                  ))
                }
                renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
              />
            </Grid>
          </Grid>
        )}

        {modalData && modalData.signatures && (
          <Grid container paddingLeft={3.5} height={'100%'} alignItems={'center'} marginTop={'20px'}>
            <Grid item xs={5}>
              <Typography variant="h5">
                <FormattedMessage id="signature" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                className={classes.multipleAutocomplete}
                multiple
                disableClearable
                //open={true}
                onChange={(event, newValue) => handleSelectSignatures(newValue)}
                options={modalData.signatures}
                disableCloseOnSelect
                disablePortal
                value={currentModalSignatures}
                isOptionEqualToValue={(option, value) => option.fieldId === value.fieldId}
                getOptionLabel={option => option.fieldName}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.fieldId}>
                    <Checkbox
                      icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                      checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {intl.formatMessage({
                      id: `signature_${option.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                    })}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                      className={classes.chip}
                      variant="outlined"
                      label={intl.formatMessage({
                        id: `signature_${option.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                      })}
                    />
                  ))
                }
                renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
              />
            </Grid>
          </Grid>
        )}

        {modalData && modalData.planTypes && (
          <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5} marginTop={'20px'}>
            <Grid item xs={5}>
              <Typography variant="h5">
                <FormattedMessage id="plan_type" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                native={false}
                className={classes.rowSelect}
                onChange={e => handleSelectPlanType(e.target.value)}
                value={currentModalPlanType ? currentModalPlanType : ''}
                IconComponent={() => (
                  <Box className={classes.groupIcon}>
                    <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                  </Box>
                )}
                MenuProps={{ className: classes.optionsGroupMenu }}
                color="secondary"
              >
                {modalData.planTypes.map(item => (
                  <MenuItem value={item.valueId} key={item.valueId}>
                    <Typography variant="h5" color="#212346">
                      {item.valueName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}

        {modalData && modalData.fields && (
          <DragDropLists
            intl={intl}
            firstListOptions={currentModalFields.availableFields}
            secondListOptions={currentModalFields.selectedFields}
            setParentState={setCurrentModalFields}
            getOptionLabel={option =>
              `${option.fieldId};${getTranslatedText(intl, 'filter', option.fieldId, option.fieldName)}`
            }
          />
        )}
      </Grid>
      <Grid container justifyContent="center" marginTop={5}>
        <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearButton}>
          <Typography variant="h5" fontWeight={700}>
            <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
          </Typography>
        </Button>
        <Box marginLeft={1}>
          <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveButton}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="save" defaultMessage="Clear" />
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(GridFiltersModal);
