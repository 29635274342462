import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from './CustomField.styles';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Autocomplete,
  Button,
  Paper,
  FormControlLabel,
  FormControl,
  MenuItem,
  Radio,
  Chip,
  RadioGroup,
  TextField,
  Typography,
  Box,
  IconButton,
  Checkbox,
  Grid,
  CircularProgress,
} from '@mui/material';
import ClearIcon from '../../icons/ClearIcon';
import CheckboxIcon from '../../icons/CheckboxIcon';
import CheckboxIconChecked from '../../icons/CheckboxIconChecked';
import { createProject, uploadFile } from '../../../../api';
import ChevronIcon from '../../icons/ChevronIcon';
import { findReturnStatement } from 'eslint-plugin-react/lib/util/ast';
import clsx from 'clsx';
import FileIcon from '../../icons/FileIcon';
import ReportDeleteIcon from '../../icons/ReportDeleteIcon';
import FileDeleteIcon from '../../icons/FileDeleteIcon';
const CustomField = ({ intl, fieldData, fieldIndex, users, setProjectData, setChanges }) => {
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [loading, setLoading] = useState(false);

  const isFieldEmpty = value => value.length > 0;
  const handleChangeField = async (event, selectValue, fieldData, fieldIndex) => {
    setChanges(true);
    let fileData = '';
    if (fieldData.fieldDefinition.fieldFormat == 'attachment') {
      setLoading(true);
      fileData = await uploadFile(event.target.files[0], event.target.files[0].name);
    }
    setProjectData(prevState => {
      if (fieldData.fieldDefinition.fieldFormat == 'date') {
        prevState.customs[fieldIndex].valueId = event?.$d;
      } else if (fieldData.fieldDefinition.fieldFormat == 'attachment') {
        prevState.customs[fieldIndex].valueId = fileData.data.upload.token;
        prevState.customs[fieldIndex].valueName = event.target.files[0].name;
        setLoading(false);
      } else if (
        fieldData.fieldDefinition.fieldFormat == 'enumeration' ||
        fieldData.fieldDefinition.fieldFormat == 'user' ||
        fieldData.fieldDefinition.fieldFormat == 'list'
      ) {
        prevState.customs[fieldIndex].fieldDefinition.values.forEach(item => {
          if (fieldData.fieldDefinition.isMultiple) {
            if (item.valueId == event.target.value) {
              item.isChecked = item.isChecked === true ? false : true;
            }
          } else {
            if (item.valueId == selectValue.valueId) {
              item.isChecked = item.isChecked === true ? false : true;
            } else {
              item.isChecked = false;
            }
          }
        });
        prevState.customs[fieldIndex].valueId = 'true';
      } else {
        prevState.customs[fieldIndex].valueId = event.target.value;
      }
      prevState.customs[fieldIndex].changed = true;
      return prevState;
    }, forceUpdate());
  };
  const deleteFile = (fieldIndex, fieldData) => {
    setChanges(true);
    setProjectData(prevState => {
      prevState.customs[fieldIndex].valueId = '';
      prevState.customs[fieldIndex].valueName = '';
      prevState.customs[fieldIndex].changed = true;
      return prevState;
    }, forceUpdate());
  };
  const renderField = () => {
    switch (fieldData.fieldDefinition.fieldFormat) {
      case 'enumeration':
      case 'user':
      case 'list':
        if (fieldData.fieldDefinition.values == null) {
          fieldData.fieldDefinition.values = [];
        }
        const PaperComponent = (props, fieldData) => {
          return (
            <Paper {...props}>
              <Box className={classes.menuGroupWrap}>{props.children}</Box>
            </Paper>
          );
        };
        return (
          <>
            <Autocomplete
              multiple={fieldData.fieldDefinition.isMultiple}
              options={fieldData.fieldDefinition.values}
              value={fieldData.fieldDefinition.values}
              popupIcon={<ChevronIcon direction="down" viewBox="0 0 24 24" />}
              disableCloseOnSelect
              disableClearable
              className={classes.autocompleteRolesList}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              PaperComponent={(e, val) => PaperComponent(e, fieldData)}
              onChange={(e, val) => handleChangeField(e, val, fieldData, fieldIndex)}
              clearIcon={null}
              getOptionLabel={option => option.valueName || ''}
              ChipProps={{
                className: classes.subItemChip,
                deleteIcon: null,
              }}
              renderOption={(props, option, { selected }) => {
                if (fieldData.fieldDefinition.isMultiple) {
                  return (
                    <MenuItem {...props} key={option.valueId} value={option.valueId}>
                      <Checkbox
                        icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                        checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                        style={{ marginRight: 8 }}
                        value={option.valueId}
                        checked={option.isChecked}
                      />
                      {option.valueName}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem
                      {...props}
                      key={option.valueId}
                      value={option.valueId}
                      className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                    >
                      {option.valueName}
                    </MenuItem>
                  );
                }
              }}
              renderInput={params => {
                const { InputLabelProps, ...restParams } = params;
                let selectedFields = fieldData.fieldDefinition.values.filter(filter => filter.isChecked);
                let selectedFieldsNames = selectedFields.map(role => role.valueName).join(',');
                return (
                  <TextField
                    {...params}
                    value={selectedFieldsNames}
                    placeholder={
                      selectedFieldsNames.length > 0 ? selectedFieldsNames : intl.formatMessage({ id: 'choose' })
                    }
                  />
                );
              }}
            />
          </>
        );
      case 'bool':
        return (
          <>
            <FormControl>
              <RadioGroup
                className={classes.booleanWrap}
                onChange={e => handleChangeField(e, '', fieldData, fieldIndex)}
                value={fieldData.valueId}
              >
                <FormControlLabel
                  className={classes.booleanLabel}
                  value={true}
                  checked={fieldData.valueId === 'true'}
                  control={
                    <Radio
                      disableRipple
                      icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                      checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                    />
                  }
                  label={
                    <Typography variant="h5">
                      <FormattedMessage id="yes" />
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.booleanLabel}
                  value={false}
                  checked={fieldData.valueId === 'false'}
                  control={
                    <Radio
                      disableRipple
                      icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                      checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                    />
                  }
                  label={
                    <Typography variant="h5">
                      <FormattedMessage id="no" />
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      case 'date':
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                components={{
                  LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                  RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                  RightArrowButton: componentProps => (
                    <IconButton disableRipple {...componentProps}>
                      {componentProps.children}
                    </IconButton>
                  ),
                  LeftArrowButton: componentProps => (
                    <IconButton disableRipple {...componentProps}>
                      {componentProps.children}
                    </IconButton>
                  ),
                }}
                renderInput={params => <TextField {...params} error={false} />}
                className={classes.datePicker}
                value={fieldData.valueId == null ? '' : fieldData.valueId}
                onChange={e => handleChangeField(e, '', fieldData, fieldIndex)}
                autoFocus={true}
              />
            </LocalizationProvider>
          </>
        );
      case 'attachment':
        return (
          <>
            {fieldData.valueName ? (
              <Box className={classes.deleteFileWrap}>
                <FileIcon className={classes.fileIcon} width={8} height={16} viewBox="0 0 8 16" />
                {fieldData.valueName}
                <IconButton onClick={e => deleteFile(fieldIndex, fieldData)} className={classes.deleteFile}>
                  <FileDeleteIcon width={10} height={12} viewBox="0 0 9.33 12" />
                </IconButton>
              </Box>
            ) : (
              <Grid container alignItems={'center'}>
                <Button className={classes.uploadButton} variant="contained" component="label">
                  {intl.formatMessage({ id: 'choose_file' })}
                  <input hidden multiple type="file" onChange={e => handleChangeField(e, '', fieldData, fieldIndex)} />
                </Button>
                {loading && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    width="30px"
                    height="30px"
                    marginLeft={'10px'}
                  >
                    <CircularProgress size={20} color="secondary" />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        );
      case 'text':
      case 'string':
      case 'float':
      case 'int':
      case 'link':
        return (
          <>
            <TextField
              hiddenLabel
              value={fieldData.valueId == null ? '' : fieldData.valueId}
              onChange={e => handleChangeField(e, '', fieldData, fieldIndex)}
              className={classes.nameInput}
              fullWidth
              placeholder={
                fieldData.valueId != null && fieldData.valueId.length > 0 ? '' : intl.formatMessage({ id: 'enter' })
              }
              variant="filled"
              type={
                fieldData.fieldDefinition.fieldFormat === 'int' || fieldData.fieldDefinition.fieldFormat == 'float'
                  ? 'number'
                  : ''
              }
              step={fieldData.fieldDefinition.fieldFormat == 'float' ? 0.1 : ''}
            />
          </>
        );
      default:
        return '';
    }
  };
  return <>{renderField()}</>;
};

export default memo(CustomField);
