import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../packages/common/shared-ui/src/components/Page';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import {
  getIssueCreate,
  getIssueEdit,
  getIssueProjects,
  getIssuesBulk,
  getIssueTrackers,
  getIssueTrackersBulk,
  getIssueTrackersConfirm,
  getParentIssueQuery,
  saveIssue,
  saveIssueBulk,
  saveIssueEdit,
  uploadFile,
} from '../../../packages/common/api';
import userPageStyles from '../administration/userPage/UserPage.styles';
import ChevronIcon from '../../../packages/common/shared-ui/src/icons/ChevronIcon';
import issueCreateEditStyles from './IssueCreateEdit.styles';

import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import IssueFormField from './components/IssueFormField';
import DeleteFileIcon from '../../../packages/common/shared-ui/src/icons/DeleteFileIcon';
import issueFormFieldStyles from './components/IssueFormField.styles';
import { useLocation, useNavigate } from 'react-router';
import issuePageStyles from '../issuePage/IssuePage.styles';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import { store } from '../app/redux';
import IssueFiles from '../issuePage/components/IssueFiles/IssueFiles';
import AttachIcon from '../../../packages/common/shared-ui/src/icons/AttachIcon';

const IssueCreateEdit = ({ intl }) => {
  const IssueState = { CREATE: 'create', EDIT: 'edit', BULK_EDIT: 'bulk_edit' };
  const PageActions = { SAVE: 'save', SAVE_AND_CONTINUE: 'save_and_continue', CANCEL: 'cancel' };
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const commonClasses = userPageStyles();
  const issueCreateEditClasses = issueCreateEditStyles();
  const issueFormClasses = issueFormFieldStyles();
  const issueCommonStyles = issuePageStyles();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [trackers, setTrackers] = useState([]);
  const [issue, setIssue] = useState({});
  const [issueAllFields, setIssueAllFields] = useState([]);
  const [parentIssueOptions, setParentIssueOptions] = useState([]);
  const [fieldsColumnsBreak, setFieldColumnsBreak] = useState({ breakCount: 0, breakState: false });
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [projectsParams, setProjectsParams] = useState({ paramsChecked: false, params: {} });
  const [trackerParams, setTrackerParams] = useState({ paramsChecked: false, params: {} });
  const [getParams, setGetParams] = useState({ paramsChecked: false, params: {} });
  const [issueState, setIssueState] = useState(IssueState.CREATE);
  const [issues, setIssues] = useState([]);
  const [defaultProject, setDefaultProject] = useState(null);
  const [defaultTracker, setDefaultTracker] = useState(null);
  const [formError, setFormError] = useState(false);
  const [issueRemovedFields, setIssueRemovedFields] = useState([]);
  const [issueNotes, setIssueNotes] = useState({
    fieldId: 'notes',
    fieldName: 'Notes',
    valueId: null,
    valueName: null,
    fieldDefinition: {
      fieldId: 'notes',
      fieldName: 'Notes',
      fieldFormat: 'text',
      isMutiple: false,
      isRequired: false,
    },
  });
  const [showNotes, setShowNotes] = useState(false);
  const pageParams = useParams();
  const navigate = useNavigate();
  const issueFormState = useRef(
    new Map([
      [IssueState.CREATE, { getResponse: getIssueCreate, saveResponse: saveIssue, pageTitle: 'issue_new_title' }],
      [IssueState.EDIT, { getResponse: getIssueEdit, saveResponse: saveIssueEdit, pageTitle: 'issue_edit_title' }],
      [
        IssueState.BULK_EDIT,
        { getResponse: getIssuesBulk, saveResponse: saveIssueBulk, pageTitle: 'issue_edit_bulk_title' },
      ],
    ]),
  );
  const urlParams = useRef(new URLSearchParams(location.search));
  const state = store.getState();

  useEffect(() => {
    location.pathname.includes(IssueState.EDIT) && setIssueState(IssueState.EDIT);
    location.pathname.includes(IssueState.BULK_EDIT) && setIssueState(IssueState.BULK_EDIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IssueState.BULK_EDIT, IssueState.EDIT, location.pathname]);

  useEffect(() => {
    const params = {};
    urlParams.current.get('projectId') && (params.checked = urlParams.current.get('projectId'));
    pageParams.parentId && (params.parentIssue = pageParams.parentId);
    setProjectsParams({ paramsChecked: true, params: params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const params = {};
    pageParams.parentId && (params.parentIssue = pageParams.parentId);
    urlParams.current.get('trackerId') && (params.checked = urlParams.current.get('trackerId'));
    selectedProject && (params.project = selectedProject.valueId);
    defaultProject && (params.project = defaultProject.valueId);
    setTrackerParams({ paramsChecked: true, params: params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, defaultProject]);

  useEffect(() => {
    const params = {};
    selectedProject && (params.project = selectedProject.valueId);
    selectedTracker && (params.tracker = selectedTracker.valueId);
    issueState === IssueState.BULK_EDIT && (params.issues = urlParams.current.get('issues').replaceAll(',', '||'));
    issueState === IssueState.CREATE && pageParams.parentId && (params.parentIssue = pageParams.parentId);
    issueState === IssueState.CREATE && pageParams.id && (params.copy = pageParams.id);
    if (issueState === IssueState.CREATE) {
      selectedProject && selectedTracker && setGetParams({ paramsChecked: true, params: params });
    } else {
      setGetParams({ paramsChecked: true, params: params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IssueState.CREATE,
    IssueState.EDIT,
    issueState,
    pageParams,
    pageParams.copyId,
    pageParams.id,
    pageParams.parentId,
    selectedProject,
    selectedTracker,
  ]);

  useEffect(() => {
    let ignore = false;
    async function getProjects() {
      const { data: projects } = await getIssueProjects(projectsParams.params);
      if (!ignore) {
        return projects;
      }
    }
    if (projectsParams.paramsChecked) {
      getProjects()
        .then(res => {
          setProjects(res);
          const checkedItem = res.find(item => item.isChecked);
          checkedItem && setSelectedProject(checkedItem);
        })
        .catch(error => handleIssueError(error.response));
      return () => {
        ignore = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsParams]);
  useEffect(() => {
    let ignore = false;
    async function getTrackers(projectId) {
      const { data: trackers } = await getIssueTrackersConfirm({ project: projectId });
      if (!ignore) {
        return trackers;
      }
    }
    if (selectedProject) {
      getTrackers(selectedProject.valueId)
        .then(res => {
          setTrackers(res);
        })
        .catch(error => handleIssueError(error.response));
      return () => {
        ignore = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);
  useEffect(() => {
    let ignore = false;
    async function getTrackers() {
      const { data: trackers } = await getIssueTrackersBulk({
        issues: urlParams.current.get('issues').replaceAll(',', '||'),
      });
      if (!ignore) {
        return trackers;
      }
    }

    if (issueState === IssueState.BULK_EDIT) {
      getTrackers()
        .then(res => {
          setTrackers(res);
        })
        .catch(error => handleIssueError(error.response));
      return () => {
        ignore = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueState]);

  useEffect(() => {
    let ignore = false;
    async function getTrackers() {
      const { data: trackers } = await getIssueTrackers(trackerParams.params);
      if (!ignore) {
        return trackers;
      }
    }
    if ((defaultProject || selectedProject) && trackerParams.paramsChecked) {
      getTrackers()
        .then(res => {
          setTrackers(res);
          const checkedItem = res.find(item => item.isChecked);
          checkedItem && setSelectedTracker(checkedItem);
          if (selectedTracker && res.data.filter(tracker => tracker.valueId === selectedTracker.valueId).length === 0) {
            setSelectedTracker(null);
            setIssueAllFields([]);
            setFieldColumnsBreak({ breakCount: 0, breakState: false });
          }
        })
        .catch(error => handleIssueError(error.response));
      return () => {
        ignore = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerParams.params]);

  useEffect(() => {
    let ignore = false;
    async function getIssueData() {
      setLoading(true);
      const response = await issueFormState.current.get(issueState).getResponse(pageParams.id, getParams.params);
      if (!ignore) {
        return response;
      }
    }
    if (getParams.paramsChecked) {
      getIssueData()
        .then(res => {
          const { data: issueData } = res;
          const formattedStandards = issueData.issue.standards?.map(field => {
            if (
              issueState !== IssueState.BULK_EDIT &&
              (field.fieldDefinition.fieldFormat === 'issue_priority' ||
                field.fieldDefinition.fieldFormat === 'issue_status')
            ) {
              const value = field.fieldDefinition.values.find(field => field.isChecked);
              return { ...field, valueId: value.valueId, valueName: value.valueName };
            } else {
              return { ...field };
            }
          });
          const formattedCustoms = issueData.issue.customs?.map(field => ({ ...field, custom: true }));
          setIssue({ ...issueData.issue });
          issueState === IssueState.EDIT && issueData.issue.hasOwnProperty('notes') && setShowNotes(true);
          issueState === IssueState.BULK_EDIT && setShowNotes(true);
          issueState === IssueState.BULK_EDIT && setIssues(issueData.issues);
          issueState === IssueState.EDIT && setIssues(issueData.issue.hierarchy);
          setIssueAllFields(formattedStandards.concat(formattedCustoms));
          issueData.services &&
            issueData.services.removed &&
            setIssueRemovedFields(issueData.services.removed.standards.concat(issueData.services.removed.customs));
          issueState === IssueState.CREATE && setPageTitle(intl.formatMessage({ id: 'issue_new_title' }));
          issueState === IssueState.EDIT &&
            setPageTitle(`${issueData.issue.hierarchy[0].subject} #${issueData.issue.hierarchy[0].issueId}`);
          issueState === IssueState.BULK_EDIT && setPageTitle(intl.formatMessage({ id: 'issue_edit_bulk_title' }));
          setLoading(false);
        })
        .catch(error => handleIssueError(error.response));
      return () => {
        ignore = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);
  useEffect(() => {
    issue.projectId &&
      issueState === IssueState.EDIT &&
      projects.length > 0 &&
      setDefaultProject(projects.find(item => Number(item.valueId) === issue.projectId));
  }, [IssueState.EDIT, issue.projectId, issueState, projects, defaultProject]);
  useEffect(() => {
    issue.trackerId &&
      issueState === IssueState.EDIT &&
      trackers.length > 0 &&
      setDefaultTracker(trackers.find(item => Number(item.valueId) === issue.trackerId));
  }, [IssueState.EDIT, issueState, issue.trackerId, trackers]);
  useEffect(() => {
    if (issueAllFields.length > 20) {
      setFieldColumnsBreak({ breakCount: parseInt((issueAllFields.length / 2).toString()), breakState: true });
    } else {
      setFieldColumnsBreak({ breakCount: 0, breakState: false });
    }
  }, [issueAllFields]);

  const clearIssue = useCallback(() => {
    setSelectedTracker(null);
    !urlParams.current.get('projectId') && setTrackers([]);
    !urlParams.current.get('projectId') && setSelectedProject(null);
    setIssue({});
    setIssueAllFields([]);
  }, []);
  const handlePageNavigation = useCallback(
    (action, id) => {
      let url = '/issues';
      const list = urlParams.current.get('list');
      if (action === PageActions.SAVE) {
        url = `/issues/${id}`;
        issueState === IssueState.BULK_EDIT && (url = `/issues`);
        state.user.project && (url = url + `?project=${state.user.project.redmineId}`);
      }
      if (action === PageActions.SAVE_AND_CONTINUE) {
        url = `/issues/create`;
        pageParams.parentId &&
          (url = `/issues/${pageParams.parentId}/create?projectId=${urlParams.current.get('projectId')}`);
        pageParams.id &&
          (url = `/issues/${pageParams.id}/copy?trackerId=${urlParams.current.get(
            'trackerId',
          )}&projectId=${urlParams.current.get('projectId')}`);
        if (state.user.project) {
          url = `/issues/create?projectId=${urlParams.current.get('projectId')}&project=${
            state.user.project.redmineId
          }`;
          pageParams.parentId &&
            (url = `/issues/${pageParams.parentId}/create?projectId=${urlParams.current.get('projectId')}&project=${
              state.user.project.redmineId
            }`);
          pageParams.id &&
            (url = `/issues/${pageParams.id}/copy?trackerId=${urlParams.current.get(
              'trackerId',
            )}&projectId=${urlParams.current.get('projectId')}&project=${state.user.project.redmineId}`);
        }
        list !== null && (url = url + '&list');
        !pageParams.id && clearIssue();
      }
      if (action === PageActions.CANCEL) {
        pageParams.id && urlParams.current.get('list') === null && (url = `/issues/${pageParams.id}`);
        pageParams.parentId && (url = `/issues/${pageParams.parentId}`);
        state.user.project && (url = url + `?project=${state.user.project.redmineId}`);
      }
      if (urlParams.current.get('agile') !== null) {
        url = `/agile?project=${urlParams.current.get('project')}`;
      }
      navigate(url);
    },
    [
      IssueState.BULK_EDIT,
      PageActions.CANCEL,
      PageActions.SAVE,
      PageActions.SAVE_AND_CONTINUE,
      clearIssue,
      issueState,
      navigate,
      pageParams.id,
      pageParams.parentId,
      state.user.project,
    ],
  );

  const handleFormField = useCallback(
    (data, field, deleted = false) => {
      let updatedObject;
      const pos = issueAllFields.map(e => e.fieldId).indexOf(field.fieldId);
      switch (field.fieldDefinition.fieldFormat) {
        case 'issue_status':
        case 'issue_priority':
        case 'user':
        case 'issue_parent':
        case 'list':
        case 'enumeration':
          updatedObject = {
            ...issueAllFields[pos],
            valueId: data ? data.valueId : '',
            valueName: data ? data.valueName : '',
            changed: true,
          };
          break;
        case 'float':
        case 'acl_percent':
        case 'int':
          updatedObject = {
            ...issueAllFields[pos],
            valueName: data,
            changed: true,
          };
          break;
        case 'date':
          updatedObject = updatedObject = {
            ...issueAllFields[pos],
            valueName: data ? dayjs(data.$d).format('YYYY-MM-DD') : '',
            changed: true,
          };
          break;
        case 'bool':
          updatedObject = {
            ...issueAllFields[pos],
            valueId: data,
            valueName: data,
            changed: true,
          };
          break;
        case 'attachment':
          updatedObject = { ...issueAllFields[pos], valueName: data.token ? data.token : null, changed: true };
          break;
        default:
          updatedObject = {
            ...issueAllFields[pos],
            valueName: data.target ? data.target.value : data.toString(),
            changed: true,
          };
          break;
      }
      deleted && (updatedObject = { ...issueAllFields[pos], valueId: '', valueName: '', changed: true, deleted: true });
      const newFields = [...issueAllFields.slice(0, pos), updatedObject, ...issueAllFields.slice(pos + 1)];
      setIssueAllFields(newFields);
    },
    [issueAllFields],
  );
  // TODO переделать
  const validateForm = useCallback(() => {
    if (issueState === IssueState.BULK_EDIT && !selectedTracker) {
      return true;
    }
    const fields = issueState === IssueState.BULK_EDIT ? issueAllFields.filter(item => item.custom) : issueAllFields;

    const emptyFields = fields.filter(
      field => field.fieldDefinition.isRequired && (field.valueName === null || field.valueName === ''),
    );
    if (emptyFields.length > 0) {
      const requiredField = issueAllFields.map(field => {
        if (issueState === IssueState.BULK_EDIT) {
          if (
            field.custom &&
            field.fieldDefinition.isRequired &&
            (field.valueName === null || field.valueName === '')
          ) {
            return { ...field, error: true };
          } else {
            field.error && delete field.error;
            return field;
          }
        } else {
          if (field.fieldDefinition.isRequired && (field.valueName === null || field.valueName === '')) {
            return { ...field, error: true };
          } else {
            field.error && delete field.error;
            return field;
          }
        }
      });
      setIssueAllFields(requiredField);
      setFormError(true);
      return false;
    } else {
      setFormError(false);
      return true;
    }
  }, [IssueState.BULK_EDIT, issueAllFields, issueState, selectedTracker]);

  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const saveNewIssue = useCallback(
    async action => {
      const validation = validateForm();
      if (!validation) {
        return;
      }
      let changedField;
      let filledStandardFields;
      let filledCustomsFields;
      let requiredFieldsStandard;
      let requiredFieldsCustom;
      let changedFieldsStandard;
      let changedFieldsCustom;
      let resultStandardArray;
      let resultCustomsArray;
      changedField = issueAllFields.filter(field => field.changed);
      filledStandardFields = issueAllFields.filter(
        field => field.valueName !== null && field.valueName !== '' && !field.custom,
      );
      filledCustomsFields = issueAllFields.filter(
        field => field.valueName !== null && field.valueName !== '' && field.custom,
      );
      issueState === IssueState.CREATE &&
        (changedField = issueAllFields.filter(field => field.changed && !field.fieldDefinition.isRequired));
      changedFieldsStandard = changedField.filter(field => !field.custom);
      changedFieldsCustom = changedField.filter(field => field.custom);
      issueState === IssueState.CREATE &&
        (requiredFieldsStandard = issueAllFields.filter(field => field.fieldDefinition.isRequired && !field.custom));
      issueState === IssueState.CREATE &&
        (requiredFieldsCustom = issueAllFields.filter(field => field.fieldDefinition.isRequired && field.custom));
      resultStandardArray = [...changedFieldsStandard];
      resultCustomsArray = [...changedFieldsCustom];
      issueState === IssueState.CREATE && (resultStandardArray = [...requiredFieldsStandard, ...changedFieldsStandard]);
      issueState === IssueState.CREATE && (resultCustomsArray = [...requiredFieldsCustom, ...changedFieldsCustom]);
      issue.copiedFrom && (resultStandardArray = [...filledStandardFields]);
      issue.copiedFrom && (resultCustomsArray = [...filledCustomsFields]);
      const numbers = ['float', 'acl_percent', 'int'];
      const result = resultStandardArray.reduce((acc, field) => {
        let value = field.valueName;
        field.fieldDefinition.fieldFormat === 'issue_parent' && (value = Number(field.valueId));
        field.fieldDefinition.values && (value = field.valueId !== '' ? Number(field.valueId) : null);
        numbers.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueName.replace(',', '.')));
        field.fieldDefinition.fieldFormat === 'bool' && (value = field.valueId === 'true');
        field.deleted && field.fieldDefinition.values && (value = field.valueId);
        acc[field.fieldId] = value;

        return acc;
      }, {});
      const resultCustoms = resultCustomsArray.map(field => {
        let value = field.valueName.toString();
        field.fieldDefinition.values && (value = field.valueId !== '' ? Number(field.valueId) : '');
        field.fieldDefinition.fieldFormat === 'bool' && (value = (field.valueId === 'true').toString());
        numbers.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueName.replace(',', '.')));
        return {
          fieldId: field.fieldId,
          valueId: value.toString(),
        };
      });
      let data = {
        ...result,
        customs: resultCustoms,
        attachments: attachments,
      };
      selectedProject && (data.projectId = Number(selectedProject.valueId));
      selectedTracker && (data.trackerId = selectedTracker ? Number(selectedTracker.valueId) : issue.trackerId);
      issue.copiedFrom && (data.copiedFrom = issue.copiedFrom);
      issueAllFields.find(field => !field.changed && field.fieldDefinition.fieldFormat === 'issue_parent') &&
        pageParams.parentId &&
        (data.parentIssue = Number(pageParams.parentId));
      issueNotes.valueName && (data.notes = issueNotes.valueName);
      issueState === IssueState.BULK_EDIT && (data = { ids: issues.map(item => item.issueId), issue: { ...data } });
      setLoading(true);
      try {
        const response = await issueFormState.current.get(issueState).saveResponse(pageParams.id, data);
        if (response) {
          const id = issueState === IssueState.CREATE ? response.data : pageParams.id;
          handlePageNavigation(action, id);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleIssueError(error.response);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      validateForm,
      issueAllFields,
      issueState,
      IssueState.CREATE,
      IssueState.BULK_EDIT,
      attachments,
      selectedProject,
      selectedTracker,
      issue.trackerId,
      issue.copiedFrom,
      pageParams.parentId,
      pageParams.id,
      issueNotes.valueName,
      issues,
      handlePageNavigation,
      handleIssueError,
    ],
  );

  const handleFileUpload = useCallback(async event => {
    setLoadingUpload(true);
    try {
      Promise.all([...event.target.files].map(async file => await uploadFile(file, file.name))).then(data => {
        const files = data.map((item, index) => ({
          token: item.data.upload.token,
          fileName: [...event.target.files][index].name,
          contentType: [...event.target.files][index].type,
        }));
        setAttachments(prev => [...prev, ...files]);
        setLoadingUpload(false);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleDeleteIssueFile = useCallback(
    index => {
      const newArray = attachments.filter((file, i) => i !== index);
      setAttachments(newArray);
    },
    [attachments],
  );

  const handleSelectProject = useCallback(value => {
    setSelectedProject(value);
  }, []);
  const handleSelectTracker = useCallback(
    value => {
      setSelectedTracker(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTracker, selectedProject],
  );

  const handleParentIssueChange = useCallback(
    async value => {
      let params = {
        search: value,
      };
      issueState === IssueState.BULK_EDIT && (params.issues = issues.map(item => item.issueId).join('||'));
      issueState === IssueState.EDIT && (params.issues = pageParams.id);
      issueState === IssueState.CREATE && selectedProject && (params.project = selectedProject.valueId);
      issueState === IssueState.CREATE && selectedTracker && (params.tracker = selectedTracker.valueId);
      try {
        const response = await getParentIssueQuery(params);
        if (response) {
          setParentIssueOptions(response.data);
        }
        //      }
      } catch (error) {
        console.error('ERROR WITH EDIT', error);
      }
    },
    [
      issueState,
      IssueState.BULK_EDIT,
      IssueState.EDIT,
      IssueState.CREATE,
      issues,
      pageParams.id,
      selectedProject,
      selectedTracker,
    ],
  );

  return (
    <Page title={pageTitle} width="100%" sx={{ marginBottom: '34px' }}>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <Typography m={0} padding={'10px 0'} variant="h1">
            {intl.formatMessage({ id: issueFormState.current.get(issueState).pageTitle })}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="defaultGreyPrimary"
            disableRipple
            onClick={() => saveNewIssue(PageActions.SAVE)}
            disabled={issueAllFields.length === 0}
          >
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="save_text" />
            </Typography>
          </Button>
          {issueState === IssueState.CREATE && (
            <Button
              onClick={() => saveNewIssue(PageActions.SAVE_AND_CONTINUE)}
              variant="defaultGreyPrimary"
              disableRipple
              sx={{ marginLeft: '8px' }}
              disabled={issueAllFields.length === 0}
            >
              <Typography variant="h4" fontWeight={700}>
                <FormattedMessage id="save_text_and_go" />
              </Typography>
            </Button>
          )}
          <Button
            onClick={() => handlePageNavigation(PageActions.CANCEL, null)}
            variant="defaultGreyPrimary"
            disableRipple
            sx={{ marginLeft: '8px' }}
          >
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="cancel" />
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container direction={'column'} sx={{ mb: '20px', mt: '4px' }}>
        {issues &&
          issues.length > 0 &&
          issues.map((item, index) => {
            return (
              <Grid item key={index}>
                <Typography variant="h5">
                  {issueState === IssueState.BULK_EDIT && (
                    <Link
                      to={`/issues/${item?.issueId}`}
                      target={'_blank'}
                      className={issueCommonStyles.issueLink}
                      style={{ color: '#082253' }}
                    >
                      {item?.issueName}
                    </Link>
                  )}
                  {issueState === IssueState.EDIT && (
                    <>
                      <Box component={'span'} color={'#082253'}>
                        {`${item.trackerName} #${item?.issueId}:`}
                      </Box>
                      <Box component={'span'} color={'#212346'} marginLeft={'4px'}>
                        {item.subject}
                      </Box>
                    </>
                  )}
                </Typography>
              </Grid>
            );
          })}
      </Grid>

      <Grid container sx={{ position: 'relative' }}>
        {formError && (
          <Box sx={{ position: 'absolute', top: '-20px', left: 0 }}>
            <Typography variant="h3" color={'#E03737'}>
              {intl.formatMessage({ id: 'issue_page_error' })}
            </Typography>
          </Box>
        )}
        {issueRemovedFields.length > 0 && (
          <Box>
            <Typography variant="h3" color={'#E03737'}>
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              {`Изменения приведут к удалению значений одного или нескольких полей выбранных объектов: `}
              {issueRemovedFields
                .map(item => {
                  const stringExists = !!intl.messages[`issue_page_${item.replaceAll(' ', '_').toLowerCase()}`];
                  if (stringExists) {
                    return intl.formatMessage({
                      id: `issue_page_${item.replaceAll(' ', '_').toLowerCase()}`,
                    });
                  } else {
                    return item;
                  }
                })
                .join(', ')}
            </Typography>
          </Box>
        )}
        <Grid container spacing={5}>
          <Grid item lg={6}>
            <Grid container direction={'column'} className={commonClasses.blockWrapper} padding={'16px 32px'}>
              <FormControl
                required={issueState !== IssueState.BULK_EDIT}
                fullWidth
                sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
              >
                <InputLabel className={issueFormClasses.selectLabel}>
                  <Typography variant="h5" color={'#41424E'}>
                    {intl.formatMessage({ id: 'issue_project_label' })}
                  </Typography>
                </InputLabel>
                <Autocomplete
                  noOptionsText={intl.formatMessage({ id: 'not_found' })}
                  disabled={!!pageParams.parentId}
                  onChange={(event, newValue) => handleSelectProject(newValue)}
                  className={issueFormClasses.filterSelect}
                  disablePortal
                  options={projects}
                  sx={{ width: 253 }}
                  disableClearable
                  renderOption={(props, option) => {
                    return (
                      <MenuItem
                        {...props}
                        selected={
                          selectedProject
                            ? option.valueId === selectedProject.valueId
                            : defaultProject
                            ? option.valueId === defaultProject.valueId
                            : false
                        }
                        key={option.valueId}
                        value={option.valueId}
                      >
                        {option.valueName}
                      </MenuItem>
                    );
                  }}
                  value={selectedProject ? selectedProject : defaultProject ? defaultProject : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={option => option.valueName || ''}
                  PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                  renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                  popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                />
              </FormControl>
              <FormControl
                required={issueState !== IssueState.BULK_EDIT}
                fullWidth
                sx={{ marginTop: '16px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
              >
                <InputLabel className={issueFormClasses.selectLabel}>
                  <Typography variant="h5" color={'#41424E'}>
                    {intl.formatMessage({ id: 'issue_tracker_label' })}
                  </Typography>
                </InputLabel>
                <Autocomplete
                  noOptionsText={intl.formatMessage({ id: 'not_found' })}
                  onChange={(event, newValue) => handleSelectTracker(newValue)}
                  className={issueFormClasses.filterSelect}
                  disablePortal
                  options={trackers}
                  sx={{ width: 253 }}
                  disableClearable
                  renderOption={(props, option) => {
                    return (
                      <MenuItem
                        {...props}
                        selected={
                          selectedTracker
                            ? option.valueId === selectedTracker.valueId
                            : defaultTracker
                            ? option.valueId === defaultTracker.valueId
                            : false
                        }
                        key={option.valueId}
                        value={option.valueId}
                      >
                        {option.valueName}
                      </MenuItem>
                    );
                  }}
                  value={selectedTracker ? selectedTracker : defaultTracker ? defaultTracker : ''}
                  getOptionLabel={option => option.valueName || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                  renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                  popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                />
              </FormControl>
              {fieldsColumnsBreak.breakState
                ? issueAllFields
                    .filter(item => item.fieldId !== 'description')
                    .slice(0, fieldsColumnsBreak.breakCount)
                    .map((field, index) => (
                      <IssueFormField
                        key={index}
                        field={field}
                        handleField={handleFormField}
                        changeParentIssue={handleParentIssueChange}
                        parentIssueOptions={parentIssueOptions}
                        bulk={issueState === IssueState.BULK_EDIT}
                      ></IssueFormField>
                    ))
                : issueAllFields
                    .filter(item => item.fieldId !== 'description')
                    .map((field, index) => (
                      <IssueFormField
                        key={index}
                        field={field}
                        handleField={handleFormField}
                        changeParentIssue={handleParentIssueChange}
                        parentIssueOptions={parentIssueOptions}
                        bulk={issueState === IssueState.BULK_EDIT}
                      ></IssueFormField>
                    ))}
              {issueAllFields.length > 0 && (
                <>
                  <FormControl
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: '16px',
                    }}
                  >
                    <InputLabel className={issueFormClasses.selectLabel} sx={{ width: '200px' }}>
                      <Typography variant="h5" color={'#41424E'}>
                        {intl.formatMessage({ id: 'issue_page_files_title' })}
                      </Typography>
                    </InputLabel>
                    <Grid container alignItems={'center'}>
                      <Button
                        variant="defaultGreyPrimary"
                        component="label"
                        sx={{ width: '115px', padding: '6px 16px' }}
                      >
                        <Typography variant="h5">{intl.formatMessage({ id: 'choose_file' })}</Typography>
                        <input
                          hidden
                          multiple
                          type="file"
                          onClick={event => (event.currentTarget.value = '')}
                          onChange={event => handleFileUpload(event)}
                        />
                      </Button>
                      {loadingUpload && (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                          width="30px"
                          height="30px"
                          marginLeft={'10px'}
                        >
                          <CircularProgress size={20} color="secondary" />
                        </Grid>
                      )}
                    </Grid>
                  </FormControl>
                  {issueState === IssueState.EDIT && <IssueFiles viewOnly></IssueFiles>}
                  {attachments.length > 0 && (
                    <Grid container direction={'column'} marginLeft={'200px'}>
                      {attachments.map((file, index) => (
                        <Grid key={index} item sx={{ lineHeight: '20px' }} marginTop={'8px'}>
                          <Grid container>
                            <Grid item>
                              <Box component={'span'} display={'flex'} alignItems={'center'}>
                                <AttachIcon />
                                <Typography variant="h4" color={'#7174AC'} marginLeft={'4px'}>
                                  {file.fileName}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item sx={{ display: 'flex', marginLeft: '13px' }}>
                              <IconButton
                                className={issueCreateEditClasses.hoveredIcon}
                                sx={{ padding: '0', marginLeft: '4px' }}
                                onClick={() => handleDeleteIssueFile(index)}
                              >
                                <DeleteFileIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {issueAllFields.length > 0 && !fieldsColumnsBreak.breakState && (
              <>
                {issueAllFields.find(item => item.fieldId === 'description') && (
                  <Grid className={commonClasses.blockWrapper} padding={'16px 32px'} marginTop={'16px'}>
                    <IssueFormField
                      field={issueAllFields.find(item => item.fieldId === 'description')}
                      handleField={handleFormField}
                      bulk={issueState === IssueState.BULK_EDIT}
                    ></IssueFormField>
                  </Grid>
                )}
                {showNotes && (
                  <Grid className={commonClasses.blockWrapper} padding={'16px 32px'} marginTop={'16px'}>
                    <IssueFormField
                      field={issueNotes}
                      handleField={event => setIssueNotes({ ...issueNotes, valueId: event, valueName: event })}
                      bulk={issueState === IssueState.BULK_EDIT}
                    ></IssueFormField>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          {fieldsColumnsBreak.breakState && (
            <Grid item lg={6}>
              <Grid container direction={'column'} className={commonClasses.blockWrapper} padding={'0 32px 16px 32px'}>
                {issueAllFields
                  .filter(item => item.fieldId !== 'description')
                  .slice(fieldsColumnsBreak.breakCount)
                  .map((field, index) => (
                    <IssueFormField
                      key={index}
                      field={field}
                      handleField={handleFormField}
                      bulk={issueState === IssueState.BULK_EDIT}
                    ></IssueFormField>
                  ))}
              </Grid>
              {issueAllFields.length > 0 && fieldsColumnsBreak.breakState && (
                <>
                  {issueAllFields.find(item => item.fieldId === 'description') && (
                    <Grid className={commonClasses.blockWrapper} padding={'16px 32px'} marginTop={'16px'}>
                      <IssueFormField
                        field={issueAllFields.find(item => item.fieldId === 'description')}
                        handleField={handleFormField}
                        bulk={issueState === IssueState.BULK_EDIT}
                      ></IssueFormField>
                    </Grid>
                  )}
                  {showNotes && (
                    <Grid className={commonClasses.blockWrapper} padding={'16px 32px'} marginTop={'16px'}>
                      <IssueFormField
                        field={issueNotes}
                        handleField={event => setIssueNotes({ ...issueNotes, valueId: event, valueName: event })}
                        bulk={issueState === IssueState.BULK_EDIT}
                      ></IssueFormField>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {loading && (
        <Grid container alignItems="center" className={issueCreateEditClasses.loader}>
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </Page>
  );
};

export default injectIntl(IssueCreateEdit);
